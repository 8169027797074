import React, { useEffect, useState } from "react";
import Protected from "./protected-route";
import Restricted from "./restricted-route";
import Authentication from "utils/redux/reducers/Auth";
import Login from "../pages/Admin/Login";
import Reset from "../pages/Admin/Reset";
import UserReset from "../pages/Admin/Reset";
import CreateNewPassword from "../pages/Admin/CreateNewPassword";
import UserLogin from "../pages/Landing/Login";
import UserSignup from "../components/Signup";
// import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { withReducer } from "./withReducer";
import { useDispatch } from "react-redux";
import { verifyToken } from "./redux/store/action";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Admin from "../pages/Admin/router";
import Home from "../pages//Landing/router";
import { userForgetPassword, userResetPasssword } from "./api/user";
import { adminForgetPassword, adminResetPasssword } from "./api/admin";
import { toast } from "react-toastify";
function Router() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loader = useSelector(({ Setting }) => Setting?.loader);
  const navigate = useNavigate();
  const userForget = (email) => {
    return userForgetPassword(email).then((res) => {
      if (res?.data?.success) {
        toast.success("Check email to reset password!");
        return navigate("/login");
      } else {
        toast.error("Something Went Wrong!");
      }
    });
  };
  const userReset = (payload, id) => {
    return userResetPasssword(payload, id).then((res) => {
      if (res?.data?.success) {
        toast.success("Password Reset Successfully!");
        return navigate("/login");
      } else {
        toast.error("Something Went Wrong!");
      }
    });
  };
  const adminForget = (email) => {
    return adminForgetPassword(email).then((res) => {
      if (res?.data?.success) {
        toast.success("Check email to reset password!");
        return navigate("/login/admin");
      } else {
        toast.error("Something Went Wrong!");
      }
    });
  };
  const adminReset = (payload, id) => {
    return adminResetPasssword(payload, id).then((res) => {
      if (res?.data?.success) {
        toast.success("Password Reset Successfully!");
        return navigate("/login/admin");
      } else {
        toast.error("Something Went Wrong!");
      }
    });
  };
  useEffect(() => {
    setLoading(loader);
  }, [loader]);
  useEffect(() => {
    dispatch(verifyToken());
  }, []);
  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex items-center justify-center bg-white absolute z-50 top-0 left-0">
          <img className="w-20" src="/loader.gif" alt="" />
        </div>
      ) : null}
      <Routes>
        <Route
          path="admin/*"
          element={
            <Protected>
              <Admin />
            </Protected>
          }
        />
        <Route
          path="/*"
          element={
            <Protected roles={true}>
              <Home />
            </Protected>
          }
        />
        <Route
          path="/login/admin"
          element={
            <Restricted>
              <Login />
            </Restricted>
          }
        />
        <Route
          path="/login"
          element={
            <Restricted>
              <UserLogin />
            </Restricted>
          }
        />
        <Route
          path="/signup"
          element={
            <Restricted>
              <UserSignup />
            </Restricted>
          }
        />
        <Route
          path="/reset"
          element={
            <Restricted>
              <UserReset adminText="" reset={userForget} />
            </Restricted>
          }
        />
        <Route
          path="/reset/admin"
          element={
            <Restricted>
              <Reset reset={adminForget} />
            </Restricted>
          }
        />
        <Route
          path="/forgot_password/:token"
          element={
            <Restricted>
              <CreateNewPassword adminText="" action={userReset} />
            </Restricted>
          }
        />
        <Route
          path="/forgot_password/admin/:token"
          element={
            <Restricted>
              <CreateNewPassword action={adminReset} />
            </Restricted>
          }
        />

        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </>
  );
}
export default withReducer("Authentication", Authentication)(Router);
