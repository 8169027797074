import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainTemplate } from "../../template";
import BehavioralHealth from "./behavioral-health";
import Encounter from "./Encounter";
import CriticalCare from "./critical-care";
function Router() {
  return (
    <MainTemplate>
      <Routes>
        <Route path="/" element={<Encounter />} />
        <Route path="/behavioralhealth" element={<BehavioralHealth />} />
        <Route path="/criticalcare" element={<CriticalCare />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MainTemplate>
  );
}
export default Router;
