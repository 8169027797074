import { React } from "react";
import Modal from "./Modal";
import { XCircleIcon } from "@heroicons/react/outline";
import moment from "moment";
function FilterPopup(props) {
  const {
    open,
    toggleOpen,
    setSelectedFilter,
    selectedFilter,
    setSelectedField,
    selectedField,
    setKeywordOne,
    setKeywordTwo,
    search,
    total,
    deleteFilter,
  } = props;

  const options = [
    {
      id: "email",
      name: "Email",
    },
    {
      id: "date_of_service",
      name: "Date Of Service",
    },
    {
      id: "createdAt",
      name: "Create At",
    },
    {
      id: "patient_location",
      name: "Patient Location",
    },
    {
      id: "service_line",
      name: "Service Line",
    },

    {
      id: "critical_care",
      name: "Critical Care",
    }
  ];
  const list = [
    { name: "After", key: ["date_of_service","createdAt"]  },
    { name: "Between", key: ["date_of_service","createdAt"] },
    { name: "Before", key: ["date_of_service","createdAt"] },
    { name: "Equal", key: [] },
    { name: "Contains", key: [] },
    
  ];
  return (
    <Modal
      open={open}
      close={toggleOpen}
      mainClass="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full max-w-xl"
      className="md:p-6 p-4"
      content={
        <div className="w-full pb-5 space-y-3">
          <div className="">
            <div className="flex flex-col mb-4">
              <label className="block text-sm tracking-wide mb-3 font-medium text-gray-600">
                Selected Filter
              </label>
              {total?.map((v, k) => {
                return (
                  <div
                    key={k}
                    className="grid grid-cols-2 mb-3 capitalize p-3 rounded-lg bg-gray-100"
                  >
                    <label className="block text-sm font-medium tracking-wide text-primary">
                      {v?.filed?.split("_")?.map((val) => `${val} `)}{" "}
                      <span className="text-gray-700">({v?.search})</span>
                    </label>
                    <div className="w-full flex items-center justify-between">
                      <label className="block text-sm tracking-wide text-gray-600">
                        {v?.selectedFilter}
                      </label>
                      <div className="w-full flex justify-end">
                        <XCircleIcon
                          onClick={deleteFilter.bind(this, k)}
                          className="w-5 text-red-500"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <label className="block text-sm tracking-wide mb-3 font-medium text-gray-600">
              Filter by condition
            </label>
            <select
              onChange={(e) => {
                setSelectedField(e?.target?.value)}}
              className={`w-full tracking-wide cursor-pointer border-none bg-grey-200 rounded-md px-4 py-3 text-xs focus:ring-0`}
              placeholder={"Select Filters"}
            >
              <option value={"0"}>{"Select Filters"}</option>
              {options &&
                options?.map((v, k) => {
                  return (
                    <option key={k} value={v?.id}>
                      {v?.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <label className="block my-3 text-sm font-semibold text-black">
            Filters
          </label>
          <div className="grid grid-cols-3 md:gap-3 gap-2 sm:grid-cols-4">
            {list.map((option, k) => (
              <button
                key={k}
                value={option}
                onClick={setSelectedFilter.bind(this, option?.name)}
                className={`${
                  (option?.key?.length > 0 &&
                    !option?.key?.includes(selectedField)) ||
                  !selectedField
                    ? "opacity-50 cursor-not-allowed"
                    : selectedFilter === option?.name
                    ? "border-transparent bg-primary text-white "
                    : "bg-grey-200 border-gray-200 text-gray-900 hover:bg-gray-50"
                }
                    border rounded-md py-2 md:px-3 px-1 h-9 flex items-center justify-center md:text-xs text-2xs font-medium sm:flex-1`}
                disabled={
                  (option?.key?.length > 0 &&
                    !option?.key?.includes(selectedField)) ||
                  !selectedField
                }
              >
                {option.name}
              </button>
            ))}
          </div>
          <div className="w-full flex flex-col pt-6">
            <input
              type={
                ["date_of_service", "createdAt"].includes(selectedField) ? "date" : "text"
              }
              onChange={(e) =>
                setKeywordOne(
                  ["date_of_service", "createdAt"].includes(selectedField)
                    ? moment(e?.target?.value).format("YYYY-MM-DD")
                    : e?.target?.value
                )
              }
              className="shadow-sm focus:border-primary py-2 px-3.5 block ring-0 w-full focus:outline-none text-xs border border-gray-300 bg-grey-200 rounded-md"
            />
            {["date_of_service", "createdAt"].includes(selectedField) &&
            ["Between"]?.includes(selectedFilter) ? (
              <input
                type={"date"}
                onChange={(e) =>
                  setKeywordTwo(moment(e?.target?.value).format("YYYY-MM-DD"))
                }
                className="shadow-sm mt-4 focus:border-primary py-2 px-3.5 block ring-0 w-full focus:outline-none text-xs border border-gray-300 bg-grey-200 rounded-md"
              />
            ) : null}
          </div>
        </div>
      }
      action={
        <div className="flex justify-between space-x-3 pt-8">
          <button
            onClick={toggleOpen}
            className="inline-flex items-center px-4 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none cursor-pointer"
          >
            Cancel
          </button>
          <button
            onClick={search}
            className="inline-flex items-center md:px-4 px-3 md:py-2 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white bg-primary hover:bg-info-600/90 focus:outline-none cursor-pointer"
          >
            Save
          </button>
        </div>
      }
    />
  );
}

export default FilterPopup;
