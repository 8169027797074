import { onGetDoctors } from "pages/Admin/store/actions";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteDoctors, assignRole } from "utils/api/admin";
import Dialog from "components/delete-popup";
import Table from "../../table";

function SiteMembers({ state }) {
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const assignRoles = (uId, role) => {
    const payload = {
      roles: role,
      uId,
    };
    return assignRole(payload).then(() => {
      toast.success("Member Assigned Role!");
      return dispatch(onGetDoctors());
    });
  };
  const deleteMembers = () => {
    return deleteDoctors(selected?.id).then(() => {
      toast.success("Member Deleted!");
      setOpen(false);
      return dispatch(onGetDoctors());
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        minWidth: 50,
        maxWidth: 14,
        accessor: "id",
        id: "id",
      },
      {
        Header: "Email",
        accessor: "email",
        width: 400,
        id: "email",
      },
      {
        Header: "Speciality",
        accessor: "speciality",
        width: 400,
        id: "emails",
      },
      {
        Header: "Co-Admin",
        accessor: (row) => {
          return (
            <>
              <input
                onChange={() =>
                  assignRoles(
                    row?.id,
                    row?.roles === "doctor" ? "co-admin" : "doctor"
                  )
                }
                id={`${row?.id}`}
                name={`${row?.id}`}
                type="checkbox"
                className="h-4 w-4 text-primary cursor-pointer focus:outline-none focus:ring-0 border-gray-300 rounded"
                checked={row?.roles !== "doctor"}
              />
              <label className={`ml-2 cursor-pointer`} htmlFor={`${row?.id}`}>
                Permissions
              </label>
            </>
          );
        },
        width: 150,
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="space-x-3">
              <button
                onClick={() => {
                  setSelected(row?.original);
                  setOpen(true);
                }}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
              >
                Delete
              </button>
            </div>
          );
        },
        minWidth: 100,
        maxWidth: 90,
      },
    ],
    []
  );

  return (
    <>
      <Dialog
        title="Delete Member?"
        description={`Are you sure to delete ${selected?.email} Member?`}
        open={open}
        setOpen={setOpen}
        callback={deleteMembers}
      />
      <Table
        setSelected={setSelected}
        columns={columns}
        data={state}
        bodyClass="py-2.5"
        notChecked={true}
      />
    </>
  );
}

export default SiteMembers;
