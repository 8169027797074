export const headers = [
  {
    label: "ID",
    key: "id",
  },
  {
    label: "Create At",
    key: "createdAt",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Date Of Service",
    key: "date_of_service",
  },
  {
    label: "Patient Location",
    key: "patient_location",
  },
  {
    label: "Service Line",
    key: "service_line",
  },
  {
    label: "Behavioral Health Facilities",
    key: "behavioral_health",
  },
  {
    label: "Behavioral Health Visit",
    key: "behavioral_visit",
  },
  {
    label: "Critical Care",
    key: "critical_care",
  },
  {
    label: "Critical Care Visit",
    key: "critical_care_visit",
  },
  {
    label: "Other Specialties ER / Inpatient TeleConsult",
    key: "other_specialties_er",
  },
  {
    label: "Other Specialties ER / Inpatient TeleConsult Visit",
    key: "other_specialties_er_visit",
  },
  {
    label: "Neuro",
    key: "neuro",
  },
  {
    label: "ERT / Transfer",
    key: "ert",
  },
  {
    label: "Subacute Care",
    key: "subacute",
  },
  {
    label: "Service Type",
    key: "service_type",
  },
  {
    label: "First Name",
    key: "first_name",
  },
  {
    label: "Last Name",
    key: "last_name",
  },
  {
    label: "Date of Birth",
    key: "dob",
  },
  {
    label: "Reason for Consult",
    key: "reason_for_consult",
  },
  {
    label: "Medical Record No",
    key: "mrn",
  },

  {
    label: "Referring Pysician",
    key: "referring_provider",
  },
];
