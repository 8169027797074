import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  onGetDoctors,
  onGetEncounterSheets,
  onGetLast24HoursEncounterSheets,
  onGetPendingRequest,
} from "./store/actions";
import Cards from "../../components/dashboard/cards";
import WelcomeBanner from "../../components/dashboard/WelcomeBanner";
import DashboardCard from "../../components/dashboard/DashboardChart";
function Dashboard() {
  const state = useSelector(({ Admin }) => Admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetDoctors());
    dispatch(onGetPendingRequest());
    dispatch(onGetEncounterSheets());
    dispatch(onGetLast24HoursEncounterSheets());
  }, []);
  const card = useCallback(() => {
    return [
      {
        to: "/admin/encounter",
        title: "Encounter Sheet",
        totalData: state?.sheets?.length,
      },
      {
        to: "/admin/site_member",
        title: "Site Members",
        totalData: state?.doctors?.members?.length,
      },
      {
        to: "/admin/site_member/pending",
        title: "Pending Request",
        totalData: state?.pendingRequests?.length,
      },
      {
        to: "/admin/dashboard",
        title: "Last 24 Hours Encounters",
        totalData: state?.encounterSheetsLast24Hours?.length,
      },
    ];
  }, [state]);
  return (
    <>
      <WelcomeBanner title="Dashboard" />
      <div className="grid lg:grid-cols-2 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
        {card()?.map((v, k) => {
          return (
            <Cards key={k} to={v.to} title={v.title} totalData={v.totalData} />
          );
        })}
      </div>
      <div className="w-full h-[500px]">
        <DashboardCard data={card} />
      </div>
    </>
  );
}

export default Dashboard;
