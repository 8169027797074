import axios from "axios";
import { toast } from "react-toastify";

// export const baseURL = `https://portal-api-dev.veeonecal.com:3000/api/`; //`${process.env.REACT_APP_API}`;
export const baseURL = `https://dev-portal-api.co-source.net/api/`; //`${process.env.REACT_APP_API}`;

const useFetch = axios.create({
  baseURL,
});
useFetch.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
    };
    if (localStorage.getItem("Access_Token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "Access_Token"
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
useFetch.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // your failure logic
    }
    return response;
  },
  async function (err) {
    toast.error(err?.response?.data?.message);
  }
);
export { useFetch };
