import React, { useState } from "react";

import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
import Header from "../../components/Hero";
import Footer from "../../components/Footer";

function MainTemplate({ children, navigations }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-y-auto">
      <Sidebar
        className="lg:hidden"
        navigations={navigations}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <main className="h-full w-full" id="topElement">
          <Header />
          <div className="px-4 md:px-8 py-8 w-full max-w-6xl mx-auto min-h-[48.5vh]">
            {children}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default MainTemplate;
