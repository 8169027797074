import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import SearchIcon from "./SearchIcon";
import axios from "axios";
import { toast } from "react-toastify";
function InputField({
  value,
  placeholder,
  id,
  type,
  name = "a",
  rules = {},
  mainClass,
  disabled,
  lableClass,
  iconSearch,
}) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext(); // retrieve all hook methods
  const [inProp, setInProp] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(iconSearch);
  // const [loading,setLoading]=useState(true)
  useEffect(() => {
    if (errors[name]?.message) {
      setInProp(true);
    } else {
      setInProp(false);
    }
  }, [errors, errors[name]]);
  const handleSearchIconClick = () => {
    setShowSearchIcon(false);
    axios
      .get(`http://icd10api.com/?code=${watch(name)}&desc=short&r=json`) // Use the API endpoint specified in the prop
      .then((response) => {
        // Handle the API response here
        if (response.data.Response === "True") {
          setValue(name, response.data.Name + "-" + response.data.Description);
          setShowSearchIcon(true);
        } else {
          setShowSearchIcon(true);
          toast.error(response.data.Error);
        }
      })
      .catch((error) => {
        setShowSearchIcon(true);
        toast.error("IDC- code fetching error");
      });
  };
  return (
    <div className={mainClass}>
      {/* <label className={lableClass}>{value}</label> */}
      <label className={`flex items-center ${lableClass}`}>
        {value}
        {showSearchIcon && ( // Conditionally render the search icon if iconSearch is true
          <>
            {/* <input type="text" className={`border-b-2 `}/> */}
            <span className="search-icon ml-2" onClick={handleSearchIconClick}>
              <SearchIcon />
            </span>
          </>
        )}
        {showSearchIcon === false && ( // Conditionally render the search icon if iconSearch is true
          <span className="search-icon ml-2" onClick={handleSearchIconClick}>
            <p>loading ..</p>
          </span>
        )}
      </label>

      <input
        type={type}
        name={name}
        id={id}
        className={`w-full tracking-wide shadow-sm border-none rounded-md p-4 text-md focus:ring-0 ${
          disabled
            ? "opacity-75 cursor-not-allowed bg-input"
            : errors && errors[name]
            ? "bg-red-100 text-red-400 placeholder:text-red-300"
            : "bg-input text-gray-500 placeholder:text-gray-400"
        }`}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name, { ...rules, shouldUnregister: true })}
      />
      <CSSTransition
        unmountOnExit
        in={inProp}
        timeout={500}
        classNames="my-node"
      >
        <p className="text-2xs mt-2 tracking-wider text-red-400 font-medium">
          * {errors[name]?.message}
        </p>
      </CSSTransition>
    </div>
  );
}
InputField.defaultProps = {
  lableClass: "block text-sm tracking-wide mb-2 font-medium text-gray-600",
};
export default React.memo(InputField);
