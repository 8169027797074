import React, { useEffect, useMemo, useState } from "react";
import { getServiceLine } from "utils/api/user";
import { CSVLink } from "react-csv";
import { headers } from "./data";
import { useNavigate } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/outline";
import Table from "../../components/table";
import moment from "moment";

function BehavioralHealth() {
  const [state, setState] = useState([]);
  const navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);

    getServiceLine({
      email: localStorage.getItem("user_email"),
      service_line: "Critical Care",
    }).then((res) => {
      const payload = res?.data?.Data;
      setState(payload);
      setLoader(false);
    });
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Date Of Service",
        accessor: "date_of_service",
        id: "date_of_service",
        Cell: ({ row }) => {
          return row?.original?.date_of_service
            ? moment(row?.original?.date_of_service)?.format("MM/DD/YYYY")
            : "--/--/----";
        },
      },
      {
        Header: "Critical Care",
        accessor: "critical_care",
        id: "critical_care",
      },
      {
        Header: "Critical Care Visit",
        accessor: "critical_care_visit",
        id: "critical_care_visit",
      },
      {
        Header: "Service Type",
        accessor: "service_type",
        id: "service_type",
      },
      {
        Header: "First Name",
        accessor: "first_name",
        id: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        id: "last_name",
      },
      {
        Header: "Date of Birth",
        accessor: "dob",
        id: "dob",
        Cell: ({ row }) => {
          return row?.original?.dob
            ? moment(row?.original?.dob)?.format("MM/DD/YYYY")
            : "--/--/----";
        },
      },
      {
        Header: "Mrn",
        accessor: "mrn",
        id: "mrn",
      },
    ],
    []
  );
  const rowSelection = (row) => {
    if (localStorage.getItem("role") !== "co-admin") {
      localStorage.setItem("update", JSON.stringify(row?.original));
      return navigation(`/?id=${row?.original?.id}`);
    }
  };
  return (
    <div className="py-5">
      <div className="w-full flex flex-col border-b-2 mb-6 pb-4">
        <h1 className="tracking-wider text-2xl font-bold text-primary">
          Critical Care
        </h1>
        <h2 className="text-gray-700 text-md mt-2 font-medium tracking-wider text-left">
          Where medicine meets the future
        </h2>
      </div>
      <div className="w-full mb-4 flex items-center justify-end">
        {localStorage.getItem("role") === "co-admin" ? (
          <CSVLink
            filename={"critical-care.csv"}
            data={state?.map((v) => ({
              ...v,
              createdAt: moment(v?.createdAt).format("MM/DD/YYYY"),
              date_of_service: moment(v?.date_of_service).format("MM/DD/YYYY"),
            }))}
            headers={headers}
          >
            <button className="inline-flex  items-center md:px-4 px-3 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white bg-primary hover:bg-info-600/90 focus:outline-none">
              Export Data <DownloadIcon className="w-5 ml-2" />
            </button>
          </CSVLink>
        ) : null}
      </div>
      <Table
        columns={columns}
        data={state}
        bodyClass="py-3.5 w-full text-black-400 hover:cursor-pointer"
        mainClass="rounded-md overflow-hidden border border-gray-900"
        tableClass=""
        theadClass="bg-gray-900 text-white font-bold"
        trClass="text-left hover:cursor-pointer hover:bg-gray-200 text-black"
        headtrClass="text-left"
        headClass="flex items-center py-5 font-medium text-xs tracking-wide"
        rowClick={rowSelection}
        notChecked={true}
        loader={loader}
      />
    </div>
  );
}

export default BehavioralHealth;
