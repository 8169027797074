import {
  GET_DOCTORS,
  GET_ENCOUNTER_SHEETS,
  GET_LAST_24_HOURS_ENCOUNTER_SHEETS,
  GET_PENDING_REQUEST,
  GET_ALL_ENCOUNTER_SHEET,
} from "../actions";

const init = {
  doctors: null,
  pendingRequests: [],
  encounterSheets: [],
  encounterSheetsLast24Hours: [],
  sheets: [],
};
const reducer = (state = init, action) => {
  switch (action?.type) {
    case GET_ALL_ENCOUNTER_SHEET: {
      return {
        ...state,
        sheets: action?.payload,
      };
    }
    case GET_LAST_24_HOURS_ENCOUNTER_SHEETS: {
      return {
        ...state,
        encounterSheetsLast24Hours: action?.payload,
      };
    }
    case GET_DOCTORS: {
      return {
        ...state,
        doctors: action?.payload,
      };
    }
    case GET_PENDING_REQUEST: {
      return {
        ...state,
        pendingRequests: action?.payload,
      };
    }
    case GET_ENCOUNTER_SHEETS: {
      return {
        ...state,
        encounterSheets: action?.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
