import { onGetDoctors, onGetPendingRequest } from "pages/Admin/store/actions";
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { acceptMember, rejectMember } from "utils/api/admin";
import Table from "../../table";

function Pending() {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const members = useSelector(({ Admin }) => Admin?.pendingRequests);
  useEffect(() => {
    if (members) {
      setState(members);
    }
  }, [members]);
  const accept = (id) => {
    return acceptMember(id).then(() => {
      toast.success("Member Accepted");
      dispatch(onGetPendingRequest());
      return dispatch(onGetDoctors());
    });
  };
  const reject = (id) => {
    return rejectMember(id).then(() => {
      toast.success("Member Rejected");
      dispatch(onGetPendingRequest());
      return dispatch(onGetDoctors());
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: ({ row }) => {
          return <>1</>;
        },
        minWidth: 50,
        maxWidth: 15,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",
        width: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        width: 200,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          return (
            <div className="space-x-2">
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                onClick={reject.bind(this, row?.original?.id)}
              >
                Reject
              </button>
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm text-white bg-primary hover:bg-info-600/90 focus:outline-none"
                onClick={accept.bind(this, row?.original?.id)}
              >
                Approve
              </button>
            </div>
          );
        },
        minWidth: 192,
        maxWidth: 35,
      },
    ],
    []
  );
  return (
    <Table
      notChecked={true}
      columns={columns}
      data={state}
      bodyClass="py-2.5"
    />
  );
}

export default Pending;
