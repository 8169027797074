import React from "react";
import Router from "utils/router";
import Store from "utils/redux/store";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./css/style.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <ToastContainer position="bottom-center" />
        <Router />
      </BrowserRouter>
    </Provider>
  );
}
export default App;
