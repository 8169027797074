import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SearchPopup from "../components/search-table-popup"; 

export default function Example({ open, setOpen, data, select }) {
  return (

    <Transition.Root show={open} as={Fragment}>
      {/* <SearchPopup/> */}
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-grey-100 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* form */}
            {/* <div>
              <input placeholder="g\"></input>
            </div> */}
            {/* form end */}
            <div className="inline-block overflow-hidden align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-x-hidden overflow-y-auto h-[50vh] border-b border-gray-200 sm:rounded-lg">
                    {/* start */}
                    {/* <SearchPopup/> */}
                    {/* end */}
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-900 sticky top-0">
                          <tr>
                            <th
                              scope="col"
                              className="md:px-6 md:py-5 p-4 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              First Name
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 md:py-5 p-4 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              Last Namee
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 md:py-5 p-4 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              Date Of Birth
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 md:py-5 p-4 text-left text-xs font-medium text-white uppercase tracking-wider"
                            >
                              MRN #
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((person, personIdx) => (
                            <tr
                              key={personIdx}
                              onClick={select.bind(this, person)}
                              className={`${
                                personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              } cursor-pointer select-none`}
                            >
                              <td className="md:px-6 p-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {person.first_name}
                              </td>
                              <td className="md:px-6 p-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                {person.last_name}
                              </td>
                              <td className="md:px-6 p-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                {person.dob}
                              </td>
                              <td className="md:px-6 p-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                {person.mrn}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {data?.length == 0 ? (
                        <div
                          className="text-center w-full flex flex-col justify-center items-center"
                          style={{ height: "calc(100% - 65px)" }}
                        >
                          No data
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
