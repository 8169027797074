import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function OutpatientServices() {
  const [FollowUp, setFollowUp] = useState(null);
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.out_patient);
  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };
  const OutpatientServices = useCallback(
    () => [
      {
        id: "99202 - New Patient, Problem focused, 20 min.",
        title: "99202 - New Patient, Problem focused, 20 min.",
      },
      {
        id: "99203 - New Patient, Detailed, 30 min.",
        title: "99203 - New Patient, Detailed, 30 min.",
      },
      {
        id: "99204 - New Patient, Comprehensive, 45 min.",
        title: "99204 - New Patient, Comprehensive, 45 min.",
      },
      {
        id: "99205 - New Patient, Comprehensive, 60 min.",
        title: "99205 - New Patient, Comprehensive, 60 min.",
      },
      {
        id: "99211 – Established Patient, Problem focused, 10 min.",
        title: "99211 – Established Patient, Problem focused, 10 min.",
      },
      {
        id: "99212 – Established Patient, Problem focused, 20 min.",
        title: "99212 – Established Patient, Problem focused, 20 min.",
      },
      {
        id: "99213 – Established Patient, Detailed, 30 min.",
        title: "99213 – Established Patient, Detailed, 30 min.",
      },
      {
        id: "99214 - Established Patient, Comprehensive, 45 min.",
        title: "99214 - Established Patient, Comprehensive, 45 min.",
      },
      {
        id: "99215 - Established Patient, Comprehensive, 60 min.",
        title: "99215 - Established Patient, Comprehensive, 60 min.",
      },
      {
        id: "99424 - Principal care management services, for a single high-risk disease.",
        title:
          "99424 - Principal care management services, for a single high-risk disease.",
      },
      {
        id: "99425 - Additional 30 minutes provided by a physician or QHP.",
        title: "99425 - Additional 30 minutes provided by a physician or QHP.",
      },
      {
        id: "99426 - 30 minutes of clinical staff time directed by physician or QHP.",
        title:
          "99426 - 30 minutes of clinical staff time directed by physician or QHP.",
      },
      {
        id: "99427 - Additional 30 minutes of clinical staff time directed by a physician or QHP.",
        title:
          "99427 - Additional 30 minutes of clinical staff time directed by a physician or QHP.",
      },
      {
        id: "99439 - Chronic Care Management, Additional 20 minutes of clinical staff time.",
        title:
          "99439 - Chronic Care Management, Additional 20 minutes of clinical staff time.",
      },
      {
        id: "99487 - Chronic Care Management, 60-minute timed service provided by clinical staff.",
        title:
          "99487 - Chronic Care Management, 60-minute timed service provided by clinical staff.",
      },
      {
        id: "99489 - Chronic Care Management, Additional 30 minutes of clinical staff time.",
        title:
          "99489 - Chronic Care Management, Additional 30 minutes of clinical staff time.",
      },
      {
        id: "99490 - Chronic Care Management, 20 minutes of clinical staff time.",
        title:
          "99490 - Chronic Care Management, 20 minutes of clinical staff time.",
      },
      {
        id: "99491 - Chronic Care Management, 30 min of clinical staff time.",
        title:
          "99491 - Chronic Care Management, 30 min of clinical staff time.",
      },
      {
        id: "99495 - TCM - Communication within 14 days of discharge.",
        title: "99495 - TCM - Communication within 14 days of discharge.",
      },
      {
        id: "99496 - TCM - Communication within 7 calendar days of discharge.",
        title:
          "99496 - TCM - Communication within 7 calendar days of discharge.",
      },
      {
        id: "G0506 – Comp. assessment of and care planning by the physician or QHP.",
        title:
          "G0506 – Comp. assessment of and care planning by the physician or QHP.",
      },
    ],
    []
  );

  const { watch, register } = useFormContext();
  const follows = watch("out_patient");
  const follow = useCallback(
    () => [
      { id: "The Villas at Poway", value: "The Villas at Poway" },
      {
        id: "Community-Based Provider Services",
        value: "Community-Based Provider Services",
      },
    ],
    []
  );

  const followData = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];
  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  return (
    <div className="">
      {/* <SelectField
        mainClass="mb-6"
        options={follow()}
        value="Outpatient Services"
        placeholder="Select Outpatient Services"
        name="out_patient"
        rules={{
          required: "Outpatient Services Is Compulsory!",
          validate: (value) =>
            value !== "0" ? true : "Outpatient Services Is Compulsory!",
        }}
      /> */}

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        value={value}
        onChange={(event, value) => onDropDownChange(event, value)}
        options={followData}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("out_patient", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Outpatient Services"
          />
        )}
      />

      <RadioField
        value="Service Type"
        list={OutpatientServices()}
        listClass="grid gap-y-6 md:grid-cols-2 grid-cols-1 mt-3 mb-6"
        rules={{
          required: "Follow Up!",
        }}
        name="service_type"
      />
    </div>
  );
}

export default OutpatientServices;
