import React from "react";
import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Logo from "../images/logo.png";
export default function CreateNewPassword(props) {
  const { action, adminText } = props;

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
  });
  const params = useParams();
  const submit = (data) => {
    return action(data, params?.token);
  };
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const ShowPassword = (e) => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const PasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={Logo} alt="Workflow" />
          <h2 className="mt-5 text-center text-2xl font-bold text-gray-900">
            {adminText}
          </h2>
          <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
            Create New Password
          </h2>
          <p className="text-center pt-2">Please enter your new passwords</p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleSubmit(submit)}
              className="space-y-6"
              method=""
            >
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    required
                    {...register("password", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <div
                    className="absolute top-0 right-0 p-2.5 cursor-pointer"
                    onClickCapture={ShowPassword}
                  >
                    {values.showPassword ? (
                      <EyeIcon className="w-4 text-black" />
                    ) : (
                      <EyeOffIcon className="w-4 text-black" />
                    )}
                  </div>
                </div>
              </div>
              {/* <div>
                <label
                  htmlFor="currentPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    {...register("currentPassword", { required: true })}
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div> */}

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-info-600/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Create New Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
CreateNewPassword.defaultProps = {
  action: "/login/admin",
  adminText: "CoSource Admin Portal",
};
