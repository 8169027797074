import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import CheckboxField from "components/checkboxField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function Neuro() {
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.neuro);
  const [FollowUp, setFollowUp] = useState(null);

  const neuro = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];

  const data = useCallback(
    () => [
      {
        id: "Thrombolytic",
        title: "Thrombolytic",
      },
      {
        id: "Thrombectomy",
        title: "Thrombectomy",
      },
      {
        id: "Transfer Facilitated",
        title: "Transfer Facilitated",
      },
      {
        id: "N/A",
        title: "N/A",
      },
    ],
    []
  );

  const { watch, register } = useFormContext();
  const follows = watch("critical_care_visit");
  const critical = watch("neuro");
  const follow = useCallback(
    () => [
      {
        id: "General Neurology",
        title: "General Neurology",
      },
      {
        id: "Neurosurgery Consult",
        title: "Neurosurgery Consult",
      },
      {
        id: "Tele-Stroke",
        title: "Tele-Stroke",
      },
    ],
    []
  );
  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };

  return (
    <div className="">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        onChange={(event, value) => onDropDownChange(event, value)}
        value={value}
        options={neuro}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("neuro", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Neuro Facilities"
          />
        )}
      />
      <RadioField
        value="Telehealth Services Provided"
        list={follow()}
        listClass="grid md:grid-cols-3 lg:grid-cols-4 gap-y-6 grid-cols-1 mt-3 mb-6"
        rules={{
          required: "Follow Up!",
        }}
        name="service_type"
      />

      {critical === "Well Star Hospitals" ? null : (
        <CheckboxField
          value="ERT / Transfer"
          list={data()}
          listClass="grid md:grid-cols-3 lg:grid-cols-4 gap-y-6 grid-cols-2 mt-3"
          rules={{
            required: "ERT / Transfer!",
          }}
          name="ert"
        />
      )}
    </div>
  );
}

export default Neuro;
