import React from "react";
import Tabs from "./tabs";
function TabsRoutes({ tabs, routes, buttons, url }) {
  return (
    <>
      <Tabs tabs={tabs} routes={url} buttons={buttons} />
      {routes}
    </>
  );
}

export default TabsRoutes;
