import InputField from "components/InputField";
import React, { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { searchPatients } from "utils/api/user";
import SearchPopup from "../search-table-popup";
import moment from "moment";
function Search() {
  const methods = useForm();
  const [state, setState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState([]);
  const { setValue } = useFormContext();
  const handleSubmit = (data) => {
    setLoader(true);
    return searchPatients(data).then((res) => {
      const payload = res?.data?.Data;
      setSearch(payload);
      setState(true);
      setLoader(false);
    });
  };
  const select = (e) => {
    Object.keys(e).forEach((v) => {
      if (v === "dob") {
        return setValue(v, moment(moment(e[v]).toDate()).format("YYYY-MM-DD"));
      }
      setValue(v, e[v]);
    });
    return setState(false);
  };
  return (
    <>
      <SearchPopup
        select={select}
        data={search}
        open={state}
        setOpen={setState}
      />
      <FormProvider {...methods}>
        <form
          onSubmitCapture={methods.handleSubmit(handleSubmit)}
          className="grid md:grid-cols-4 grid-cols-1 gap-8"
        >
          <InputField
            value="First Name"
            type="text"
            placeholder="Enter First Name"
            name="first_name"
            mainClass="flex-1"
          />
          <InputField
            value="Last Name"
            type="text"
            placeholder="Enter Last Name"
            name="last_name"
            mainClass="flex-1"
          />
          <InputField
            value="MRN #"
            type="text"
            placeholder="Enter MRN #"
            name="mrn"
            mainClass="flex-1"
          />
          <div className="flex justify-end items-end">
            <button
              type="submit"
              className="relative flex-1 inline-flex justify-center items-center px-10 py-3 text-sm font-medium rounded-md text-black bg-primary hover:bg-opacity-75"
            >
              {loader ? "Searching..." : "Search"}
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default Search;
