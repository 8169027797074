import React, { useEffect, useState } from "react";
import PendingTable from "../../components/dashboard/Tables/Pending";
import TabsRoutes from "../../components/TabsRoutes";
import SiteMembersTable from "../../components/dashboard/Tables/SiteMembers";
import WelcomeBanner from "../../components/dashboard/WelcomeBanner";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
function SiteMembers() {
  const [state, setState] = useState([]);
  const members = useSelector(({ Admin }) => Admin?.doctors?.members);
  const tabs = [
    { name: "Site Members", to: "/" },
    { name: "Pending", to: "/pending" },
  ];
  useEffect(() => {
    if (members) {
      setState(members);
    }
  }, [members]);
  const route = useLocation();
  return (
    <div>
      <WelcomeBanner title="Site Members" />
      <TabsRoutes
        tabs={tabs}
        url={"/admin/site_member"}
        routes={
          <Routes>
            <Route path="/">
              <Route index element={<SiteMembersTable state={state} />} />
              <Route path="/pending" element={<PendingTable />} />
            </Route>
          </Routes>
        }
      />
    </div>
  );
}

export default SiteMembers;
