import { useSelector } from "react-redux";
import { Navigate, useLocation, Routes } from "react-router-dom";
const PrivateRoute = ({ children, roles }) => {
  const path = ["/", "/behavioralhealth", "/criticalcare"];
  let location = useLocation();
  const { auth } = useSelector(({ Authentication }) => Authentication);
  const userHasRequiredRole = localStorage.getItem("user_email");
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (auth && userHasRequiredRole && !path.includes(location.pathname)) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  else if(auth && !userHasRequiredRole && path.includes(location.pathname)){
    return <Navigate to="/admin/dashboard" state={{ from: location }} />;

  }
  return children;
};
export default PrivateRoute;
