import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Chart(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const payload = props?.data();
    if (payload) {
      setData([
        {
          name: "Intial",
          sheet: 0,
          site: 0,
          pending: 0,
          last24: 0,
        },
        {
          name: "Encounter Sheet",
          sheet: payload[0]?.totalData,
          site: 0,
          pending: 0,
          last24: 0,
        },
        {
          name: "Site Members",
          sheet: 0,
          site: payload[1]?.totalData,
          pending: 0,
          last24: 0,
        },
        {
          name: "Pending Request",
          sheet: 0,
          site: 0,
          pending: payload[2]?.totalData,
          last24: 0,
        },
        {
          name: "Last 24 Hours Encounters",
          sheet: 0,
          site: 0,
          pending: 0,
          last24: payload[3]?.totalData,
        },
      ]);
    }
  }, [props]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="sheet"
          stackId="1"
          stroke="#8884d8"
          fill="#8884d8"
        />
        <Area
          type="monotone"
          dataKey="site"
          stackId="1"
          stroke="#82ca9d"
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          dataKey="pending"
          stackId="1"
          stroke="#ffc658"
          fill="#ffc658"
        />
        <Area
          type="monotone"
          dataKey="last24"
          stackId="1"
          stroke="#ff2658"
          fill="#ff2758"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
