import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AdminTemplate } from "../../template";
import Dashboard from "./Dashboard";
import Encounter from "./Encounter";
import SiteMembers from "./SiteMembers";
import { withReducer } from "utils/withReducer";
import { useDispatch } from "react-redux";
import Reducer from "./store/reducer";
import {
  onGetDoctors,
  onGetEncounterSheets,
  onGetLast24HoursEncounterSheets,
  onGetPendingRequest,
} from "./store/actions";
function Router() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetDoctors());
    dispatch(onGetPendingRequest());
    dispatch(onGetEncounterSheets());
    dispatch(onGetLast24HoursEncounterSheets());
  }, []);
  // const router = useRouteMatch();
  return (
    <AdminTemplate>
      <Routes>
        <Route path={`/dashboard`} element={<Dashboard />} />
        <Route path={`/encounter`} element={<Encounter />} />
        <Route path={`/site_member/*`} element={<SiteMembers />} />
      </Routes>
    </AdminTemplate>
  );
}
export default withReducer("Admin", Reducer)(React.memo(Router));
