import { useDispatch } from "react-redux";
import Login from "../../components/Login";
import { onLoginAdmin } from "./store/actions";
export default function AdminLogin() {
  const dispatch = useDispatch();
  const loginHandler = (data) => {
    return dispatch(onLoginAdmin(data));
  };
  return (
    <Login
      bottomClass="hidden"
      forgotLink="/reset/admin"
      handler={loginHandler}
    />
  );
}
