import React, { useCallback, useEffect, useRef, useState } from "react";
import InputField from "components/InputField";
import RadioField from "components/RadioField";
import BehavioralHealth from "components/encounter/behavioral-health";
import CriticalCare from "components/encounter/critical-care";
import Neuro from "components/encounter/neuro";
import Search from "components/encounter/search";
import SubacuteCare from "components/encounter/Subacute-Care";
import OutpatientServices from "components/encounter/outpatient-services";
import Hospitalist from "components/encounter/hospitalist";
import ER from "components/encounter/er-counter";
import { CSSTransition, SwitchTransition } from "react-transition-group";
// import {Heroicon} from '@heroicons/react';
// import { TailwindCSSIcon } from "@heroicons/react";

import { useForm, FormProvider } from "react-hook-form";
import {
  createEncounter,
  updateEncounter,
  deleteEncounter,
} from "utils/api/user";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewPopup from "../../components/ReviewPopup";
import CheckboxField from "components/checkboxField";
let count = 0;
function Encounter() {
  const topRef = useRef(null);
  let item = localStorage.getItem("speciality");
  const [selectedServiceLine, setSelectedServiceLine] = useState(item);
  // console.log()
  const [state, setState] = useState(null);
  const [edit, setEdit] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const methods = useForm({ mode: "onChange" });
  const { search } = useLocation();
  const [popup, SetPopup] = useState(false);
  const toggle = () => SetPopup(!popup);
  const toggleopup = (e) => {
    e?.preventDefault();
    const payload = methods.getValues();
    setState(payload);
    return toggle();
  };
  useEffect(() => {
    count += 1;
  });
  const patient = useCallback(
    () => [
      { id: "Emergency Department", title: "Emergency Department" },
      { id: "Inpatient", title: "Inpatient" },
      { id: "Outpatient", title: "Outpatient" },
      { id: "Home", title: "Home" },
      { id: "Skilled Nursing Facility", title: "Skilled Nursing Facility" },
    ],
    []
  );

  const serviceLine = useCallback(
    () => [
      {
        id: "Behavioral Health",
        title: "Behavioral Health",
        component: <BehavioralHealth />,
      },
      {
        id: "Critical Care",
        title: "Critical Care",
        component: <CriticalCare />,
      },
      {
        id: "Hospitalist",
        title: "Hospitalist",
        component: <Hospitalist />,
      },
      {
        id: "Neuro",
        title: "Neuro",
        component: <Neuro />,
      },
      {
        id: "Subacute Care",
        title: "Subacute Care",
        component: <SubacuteCare />,
      },
      {
        id: "Outpatient Services",
        title: "Outpatient Services",
        component: <OutpatientServices />,
      },
      {
        id: "Other Specialties ER / Inpatient TeleConsult",
        title: "Other Specialties ER / Inpatient TeleConsult",
        listClass2: "md:col-span-2",
        component: <ER />,
        show: true,
      },
    ],
    []
  );

  useEffect(() => {
    methods.setValue("service_line", localStorage.getItem("speciality"));
  }, []);

  const onSubmit = (data) => {
    setLoader(true);
    console.log("data in api call", data);

    return search
      ? updateEncounter(data).then((res) => {
          setLoader(false);
          if (res.data.success) {
            if (
              selectedServiceLine?.id === "Behavioral Health" &&
              res?.data?.success
            ) {
              toast.success("Encounter Updated!");
              navigation(`/behavioralhealth`);
            } else if (
              selectedServiceLine?.id === "Critical Care" &&
              res?.data?.success
            ) {
              methods.reset();
              toast.success("Critical Updated!");
              navigation(`/criticalcare`);
            } else {
              toast.success("Update Successfully.");
            }
            methods.setValue("email", localStorage.getItem("user_email"));
            methods.setValue(
              "service_line",
              localStorage.getItem("speciality")
            );
          } else {
            methods.reset();
            methods.setValue("email", localStorage.getItem("user_email"));
            return toast.error("Something Went Wrong!");
          }
        })
      : createEncounter({
          ...data,
          ert: critical === "Well Star Hospitals" ? "" : data?.ert?.join(","),
        }).then(async (res) => {
          setLoader(false);

          if (res?.data?.success) {
            methods.reset();
            methods.setValue("email", localStorage.getItem("user_email"));
            methods.setValue(
              "service_line",
              localStorage.getItem("speciality")
            );
            // if (topRef && topRef.current) {
            //   topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // }

            const elementToScroll = document.getElementById("redd"); // Replace 'element-id' with the actual ID of the element
            var topElement = document.getElementById("topElement");

            // Scroll to the top of the page
            topElement.scrollIntoView({ behavior: "smooth" });
            return toast.success("Encounter Created");
          } else {
            return toast.error("Something Went Wrong!");
          }
        });
  };
  const service_line = methods.watch("service_line");
  useEffect(() => {
    if (service_line) {
      const payload = serviceLine().find((v) => v?.id === service_line);
      setSelectedServiceLine(item);
    } else {
      setSelectedServiceLine(item);
    }
  }, [service_line]);
  useEffect(() => {
    methods.setValue("email", localStorage.getItem("user_email"));
    if (search) {
      let payload = localStorage.getItem("update");
      payload = payload ? JSON.parse(payload) : null;
      if (payload) {
        setEdit(payload);
        Object.keys(payload).forEach((v) => {
          return methods.setValue(v, payload[v]);
        });
      }
    }
  }, []);
  const critical = methods.watch("neuro");
  const reset = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    const value = methods.getValues();
    if (value) {
      setSelectedServiceLine(item);
      let payload = {};
      Object.keys(value)?.map((v) => {
        return v !== "email" ? (payload[v] = null) : (payload[v] = value[v]);
      });
      return methods.reset(payload, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: true,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      });
    }
  };
  const deleteRecord = () => {
    deleteEncounter(edit.email, edit.id);
    if (selectedServiceLine?.id === "Behavioral Health") {
      setTimeout(() => navigation(`/behavioralhealth`), 1000);
      toast.success("Encounter Deleted Successfully.");
    } else {
      setTimeout(() => navigation(`/criticalcare`), 1000);

      toast.success("Critical Deleted Successfully.");
    }
  };

  return (
    <>
      {selectedServiceLine === "End User" ? (
        <p style={{ textAlign: "center", color: "red" }}>
          Based on your speciality your access has been restricted to this form
        </p>
      ) : (
        <>
          <div className="py-5">
            <h1 className="tracking-wider lg:text-4xl md:text-4xl text-2xl font-bold text-black">
              CoSource - Your Success Is Our Innovation!
            </h1>
            <h2 className="text-gray-900 lg:text-xl md:text-xl text-base font-bold text-md mt-2   font-medium tracking-wider text-left">
              Accelerating Healthcare Solutions
            </h2>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex flex-col my-5 w-full ">
                  <div className="flex flex-col">
                    <h2 className="text-xl font-bold tracking-wide mt-5 w-full border-b pb-3 text-black">
                      Basic Detail
                    </h2>
                    <div className="grid md:grid-cols-2 grid-cols-1 py-6 gap-8">
                      <InputField
                        lableClass="text-xl font-bold tracking-wide text-black"
                        disabled={true}
                        value="User Email"
                        type="email"
                        placeholder="Enter Your Email"
                        rules={{
                          required: "Email Is Required!",
                        }}
                        name="email"
                      />
                      <InputField
                        lableClass="text-xl font-bold tracking-wide text-black"
                        className="cursor-pointer"
                        value="Date Of Service"
                        type="date"
                        placeholder="Select Date Of Service"
                        rules={{
                          required: "Date Of Service Is Required!",
                        }}
                        name="date_of_service"
                      />
                    </div>
                  </div>
                  {/* Position change start */}
                  <div className="flex flex-col mt-4">
                    <h2 className="text-xl font-bold mb-6 tracking-wide w-full border-b pb-3 text-black">
                      Service Facility
                    </h2>
                    {item && renderServiceLines()}
                    <div className="w-full mt-6">
                      <SwitchTransition>
                        <CSSTransition
                          unmountOnExit
                          key={
                            selectedServiceLine
                              ? selectedServiceLine?.id
                              : "temp"
                          }
                          addEndListener={(node, done) =>
                            node.addEventListener("transitionend", done, false)
                          }
                          timeout={200}
                          classNames="my-node2"
                        >
                          <div className="w-full">
                            {selectedServiceLine
                              ? selectedServiceLine?.component
                              : null}
                          </div>
                        </CSSTransition>
                      </SwitchTransition>
                    </div>
                  </div>
                  {/* Position change end */}
                  <div className="flex flex-col mt-6">
                    <h2 className="text-xl font-bold mb-6 tracking-wide w-full border-b pb-3 text-black">
                      Patient Location
                    </h2>
                    <RadioField
                      list={patient()}
                      listClass="grid lg:grid-cols-4 md:grid-cols-3 gap-y-6 grid-cols-1"
                      rules={{
                        required: "Patient Location!",
                      }}
                      name="patient_location"
                    />
                  </div>
                  <div className="flex flex-col mt-8">
                    <h2 className="text-xl font-bold mb-6 tracking-wide w-full border-b pb-3 text-black">
                      Search Patients
                    </h2>
                    <Search />
                  </div>
                  <div className="flex flex-col mt-12">
                    <h2 className="text-xl font-bold mb-6 tracking-wide w-full border-b pb-3 text-black">
                      Patient Details
                    </h2>
                    <div className="grid md:grid-cols-3 gap-x-8 gap-y-4 ">
                      <InputField
                        value="First Name"
                        type="text"
                        placeholder="Enter First Name"
                        name="first_name"
                        mainClass="flex-1 text-xl font-bold"
                        rules={{
                          required: "Firt Name Is Required!",
                        }}
                      />
                      <InputField
                        value="Last Name"
                        type="text"
                        placeholder="Enter Last Name"
                        name="last_name"
                        mainClass="flex-1"
                        rules={{
                          required: "Last Name Is Required!",
                        }}
                      />
                      <InputField
                        value="Medical Record Number"
                        type="text"
                        placeholder="Enter Medical Record Number"
                        name="mrn"
                        mainClass="flex-1"
                        rules={{
                          required: "Medical Record Number Is Required!",
                        }}
                      />
                      {critical === "Well Star Hospitals" ? null : (
                        < >
                          <InputField
                            value="Date Of Birth"
                            type="date"
                            placeholder="Select Date Of Birth"
                            name="dob"
                            mainClass="flex-1"
                            rules={{
                              required: "Date Of Birth Is Required!",
                            }}
                          />
                          {/* test starrt */}

                          {/* test end */}
                          <InputField
                            value="Reason for Consults"
                            type="text"
                            placeholder="Enter Reason for Consult"
                            name="reason_for_consult"
                            mainClass="flex-1"
                            iconSearch={true}
                            rules={{
                              required: "Reason for Consult Is Required!",
                            }}
                          />
                        </>
                      )}
                      <InputField
                        value="Referring Physician"
                        type="text"
                        placeholder="Enter Referring Physician"
                        name="referring_provider"
                        mainClass="flex-1"
                        rules={{
                          required: "Referring Physician Is Required!",
                        }}
                      />
                    </div>
                  </div>
                  <CheckboxField
                    name="certify"
                    id="certify"
                    title="I certify that the information submitted in this application is true and correct to the best of my knowledge. I further understand that any false statements may result in denial or revocation of services provided."
                  />
                </div>
                <div className="w-full flex md:flex-row flex-col md:items-center items-start justify-between md:space-x-3 space-y-3 md:space-y-0">
                  <div className="flex md:space-x-4 space-x-3 items-center justify-between w-full">
                    <button
                      onClick={toggleopup}
                      className="flex items-center justify-center px-10 py-3 text-sm w-full md:w-auto font-medium rounded-md text-white bg-gray-900 hover:bg-opacity-75"
                    >
                      Review
                    </button>

                    {search ? (
                      <button
                        onClick={deleteRecord}
                        className="flex items-center justify-center px-10 py-3 text-sm w-full md:w-auto font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                      >
                        Delete
                      </button>
                    ) : (
                      <button
                        onClick={reset}
                        className="flex items-center justify-center px-10 py-3 text-sm w-full md:w-auto font-medium rounded-md text-white bg-gray-900 hover:bg-opacity-75"
                      >
                        Reset
                      </button>
                    )}
                  </div>

                  <button
                    disabled={loader}
                    type="submit"
                    className="flex items-center justify-center px-10 py-3 text-sm w-full md:w-auto font-medium rounded-md text-black bg-primary hover:bg-opacity-75"
                  >
                    {loader ? "Loading..." : search ? "Update" : "Submit"}
                  </button>
                </div>
                <ReviewPopup state={state} open={popup} toggleOpen={toggle} />
              </form>
            </FormProvider>
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(Encounter);

function renderServiceLines() {
  let item = localStorage.getItem("speciality");

  if (item == "Behavioral Health") {
    return <BehavioralHealth />;
  } else if (item == "Critical Care") {
    return <CriticalCare />;
  } else if (item == "Hospitalist") {
    return <Hospitalist />;
  } else if (item == "Neuro") {
    return <Neuro />;
  } else if (item == "Subacute Care") {
    return <SubacuteCare />;
  } else if (item == "Outpatient Services") {
    return <OutpatientServices />;
  } else if (item == "Other Specialties ER / Inpatient TeleConsult") {
    return <ER />;
  }
}
