import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { getServiceLine } from "utils/api/user";
import Table from "../../components/table";
import { headers } from "./data";
import { DownloadIcon } from "@heroicons/react/outline";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
function BehavioralHealth() {
  const [state, setState] = useState([]);
  const [role, setRole] = useState();
  console.log('role', role);
  const [serviceLineValue, setServiceLineValue] = useState('');
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    setLoader(true);
    getServiceLine({
      email: localStorage.getItem("user_email"),
      // service_line: "Behavioral Health",
      service_line: serviceLineValue,
    }).then((res) => {

      const payload = res?.data?.Data;
      setLoader(false);
      setState(payload);
      setRole(res.data.role)
    });
  }, [serviceLineValue]);
  const rowSelection = (row) => {
    // if (localStorage.getItem("role") !== "co-admin") {
    if (role !== "co-admin") {
      localStorage.setItem("update", JSON.stringify(row?.original));
      return navigation(`/?id=${row?.original?.id}`);
    }
  };
  const onDropDownChange = (event, value) => {
    if (value) {
      setServiceLineValue(value.value)
    }
    else {
      setServiceLineValue('')
    }
  }
  const dropDownOption = [
    { label: 'Behavioral Health', value: 'Behavioral Health' },
    { label: 'Critical Care', value: 'Critical Care' },
    { label: 'Hospitalist', value: 'Hospitalist' },
    { label: 'Neuro (TeleStroke Program)', value: 'Neuro' },
    { label: 'Subacute Care', value: 'Subacute Care' },
    { label: 'Outpatient Services', value: 'Outpatient Services' },
    { label: 'Other Specialties ER / Inpatient TeleConsult', value: 'Other Specialties ER / Inpatient TeleConsult' },
  ]
  const columns = useMemo(
    () => [
      {
        Header: "Date Of Service",
        accessor: "date_of_service",
        id: "date_of_service",
        Cell: ({ row }) => {
          return row?.original?.date_of_service
            ? moment(row?.original?.date_of_service)?.format("MM/DD/yyyy")
            : "--/--/----";
        },
      },
      {
        Header: "Patient Location",
        accessor: "patient_location",
        id: "patient_location",
      },
      {
        Header: "Service Line",
        accessor: "service_line",
        id: "service_line",
      },
      {
        Header: "Behavioral Health",
        accessor: "behavioral_health",
        id: "behavioral_health",
      },
      {
        Header: "Behavioral Visit",
        accessor: "behavioral_visit",
        id: "behavioral_visit",
      },
      {
        Header: "critical Care",
        accessor: "critical_care",
        id: "critical_care",
      },
      {
        Header: "critical Care Visit",
        accessor: "critical_care_visit",
        id: "critical_care_visit",
      },
      {
        Header: "Hospitalist",
        accessor: "hospitalist",
        id: "hospitalist",
      },
      {
        Header: "hospitalist Visit",
        accessor: "hospitalist_visit",
        id: "hospitalist_visit",
      },
      {
        Header: "Neuro",
        accessor: "neuro",
        id: "neuro",
      },
      {
        Header: "Subacute",
        accessor: "subacute",
        id: "subacute",
      },
      {
        Header: "Out Patient Services",
        accessor: "out_patient",
        id: "out_patient",
      },
      {
        Header: "Other Specialties ER / Inpatient TeleConsult",
        accessor: "other_specialties_er",
        id: "other_specialties_er",
      },
      {
        Header: "Other Specialties ER / Inpatient TeleConsult Visit",
        accessor: "other_specialties_er_visit",
        id: "other_specialties_er_visit",
      },
      {
        Header: "Service Type",
        accessor: "service_type",
        id: "service_type",
      },
      {
        Header: "First Name",
        accessor: "first_name",
        id: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        id: "last_name",
      },
      {
        Header: "Date of Birth",
        accessor: "dob",
        id: "dob",
        Cell: ({ row }) => {
          return row?.original?.dob
            ? moment(row?.original?.dob)?.format("MM/DD/yyyy")
            : "--/--/----";
        },
      },
      {
        Header: "Mrn",
        accessor: "mrn",
        id: "mrn",
      },
      {
        Header: "Reason for Consult",
        accessor: "reason_for_consult",
        id: "reason_for_consult",
      },
      {
        Header: "Referring Physician",
        accessor: "referring_provider",
        id: "referring_provider",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",
      },
    ],
    []
  );
  return (
    <div className="py-5">
      <div className="w-full flex flex-col border-b-2 mb-6 pb-4">
        <h1 className="tracking-wider lg:text-4xl md:text-4xl text-2xl font-bold text-black">
          Reports
        </h1>
        <h2 className="text-gray-900 lg:text-xl md:text-xl text-base font-bold text-md mt-2   font-medium tracking-wider text-left">
          Accelerating Healthcare Solutions
        </h2>
      </div>
      <div className="w-full mb-4 flex items-center justify-end">
        {/* {localStorage.getItem("role") === "co-admin" ? ( */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          size="small"
          onChange={(event, value) => onDropDownChange(event, value)}
          options={dropDownOption}
          sx={{ width: 300, mr: 1, color: '#00B0CB' }}
          renderInput={(params) => <TextField {...params} label="Select Service Line" />}
        />
        <CSVLink
          filename={"reports.csv"}
          data={state?.map((v) => ({
            ...v,
            createdAt: moment(v?.createdAt).format("MM/DD/YYYY"),
            date_of_service: moment(v?.date_of_service).format("MM/DD/YYYY"),
          }))}
          headers={headers}
        >

          <button className="inline-flex  items-center md:px-4 px-3 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white bg-primary hover:bg-info-600/90 focus:outline-none">
            Export Data <DownloadIcon className="w-5 ml-2" />
          </button>

        </CSVLink>

        {/* ) : null} */}
      </div>
      <div>

      </div>
      <Table
        columns={columns}
        data={state}
        bodyClass="py-3.5 w-full text-black-400 hover:cursor-pointer"
        mainClass="rounded-md overflow-hidden border border-gray-900"
        tableClass=""
        theadClass="bg-gray-900 text-white font-bold"
        trClass="text-left hover:cursor-pointer hover:bg-gray-200 text-black"
        headtrClass="text-left"
        headClass="flex items-center py-5 font-medium text-xs tracking-wide"
        rowClick={rowSelection}
        notChecked={true}
        loader={loader}
      />
    </div>
  );
}

export default BehavioralHealth;
