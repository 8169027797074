import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
export default function RadioField({
  value,
  list,
  listClass,
  name,
  title,
  id,
  rules,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods
  const [inProp, setInProp] = useState(false);
  useEffect(() => {
    if (errors[name]?.message) {
      setInProp(true);
    } else {
      setInProp(false);
    }
  }, [errors, errors[name]]);
  return (
    <div>
      {list ? (
        <>
          <label className="block text-sm tracking-wide mb-2 font-medium text-gray-600">
            {value}
          </label>
          <div className={`${listClass}`}>
            {list?.map((v, k) => (
              <div key={k} className="flex">
                <div className="flex items-center">
                  <input
                    id={v.id}
                    name={`${name}[${k}]`}
                    value={v.title}
                    type="checkbox"
                    className={`focus:ring-0 h-5 border-2 w-5 cursor-pointer ${
                      errors && errors[name]
                        ? "text-red-400 border-red-300"
                        : "text-primary border-gray-300"
                    }`}
                    {...register(name, { ...rules, shouldUnregister: true })}
                  />
                  <label
                    htmlFor={v.id}
                    className="pl-3 block text-sm font-medium text-gray-700 cursor-pointer"
                  >
                    {v.title}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div key={id} className="flex py-8">
          <input
            id={id}
            name={`${name}`}
            type="checkbox"
            required
            className={`focus:ring-0 h-5 border-2 w-5 cursor-pointer mt-0.5 ${
              errors && errors[name]
                ? "text-red-400 border-red-300"
                : "text-primary border-gray-300"
            }`}
            // {...register(name, { ...rules, shouldUnregister: true })}
          />
          <label
            htmlFor={id}
            className="pl-3 block md:text-md text-sm font-medium text-gray-700 cursor-pointer"
          >
            {title}
          </label>
        </div>
      )}
    </div>
  );
}
