import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InformationCircleIcon } from "@heroicons/react/solid";
function RadioField({
  value,
  list,
  listClass,
  type,
  selectedFilter,
  setSelectedFilter,
  selected,
  name,
  rules,
  lableClass,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods
  const [inProp, setInProp] = useState(false);
  useEffect(() => {
    if (errors[name]?.message) {
      setInProp(true);
    } else {
      setInProp(false);
    }
  }, [errors, errors[name]]);
  return (
    <div>
      {type === "filter" ? (
        <>
          <label className="block my-3 text-sm font-semibold text-black">
            {value}
          </label>
          <div className="grid grid-cols-3 md:gap-3 gap-2 sm:grid-cols-4">
            {list.map((option, k) => (
              <button
                key={k}
                value={option}
                onClick={setSelectedFilter.bind(this, option?.name)}
                className={`${
                  (option?.key?.length > 0 &&
                    !option?.key?.includes(selected?.id)) ||
                  !selected
                    ? "opacity-50 cursor-not-allowed"
                    : selectedFilter === option?.name
                    ? "border-transparent bg-primary text-white "
                    : "bg-grey-200 border-gray-200 text-gray-900 hover:bg-gray-50"
                }
                    border rounded-md py-2 md:px-3 px-1 h-9 flex items-center justify-center md:text-xs text-2xs font-medium sm:flex-1`}
                disabled={
                  (option?.key?.length > 0 &&
                    !option?.key?.includes(selected?.id)) ||
                  !selected
                }
              >
                {option.name}
              </button>
            ))}
          </div>
        </>
      ) : (
        <>
          <label className={`${lableClass}`}>{value}</label>
          <div className={`${listClass}`}>
            {list?.map((v, k) => (
              <div className={`${v.listClass2} flex`} key={k}>
                <div className="flex items-center">
                  <input
                    id={v.id}
                    name={name}
                    value={v.title}
                    type="radio"
                    className={`focus:ring-0 h-5 border-2 w-5 cursor-pointer ${
                      errors && errors[name]
                        ? "text-red-400 border-red-300"
                        : "text-primary border-gray-300"
                    }`}
                    {...register(name, { ...rules, shouldUnregister: true })}
                  />
                  <label
                    htmlFor={v.id}
                    className="pl-3 block md:text-sm text-xs font-medium text-gray-700 cursor-pointer"
                  >
                    {v.title}{" "}
                    {v.title === "Neuro" ? (
                      <span>(TeleStroke Program)</span>
                    ) : null}
                  </label>
                </div>
                {v.show === true ? (
                  <div className="relative p-1 group">
                    <InformationCircleIcon className="w-4 -mt-2 text-black cursor-pointer" />
                    <ul className="absolute z-20 md:top-3 bottom-7 md:left-5 right-3 h-36 rounded bg-black w-32 text-white text-2xs space-y-1 py-2.5 px-3 font-light invisible group-hover:visible transform transition-all ease-linear">
                      <li>Cardio</li>
                      <li>Endo</li>
                      <li>Infectious Disease</li>
                      <li>Nephrology</li>
                      <li>Podiatry</li>
                      <li>Pulmonary</li>
                    </ul>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
RadioField.defaultProps = {
  // lableClass: "text-xl font-semibold tracking-wide w-full text-black mb-2",
  lableClass:
    "text-xl font-bold mb-6 tracking-wide w-full  pb-3 text-black",
};
export default React.memo(RadioField);
