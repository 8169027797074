import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signupUser } from "utils/api/user";
import Logo from "../images/logo-new.png";
import { useState, useCallback } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import SelectField from "components/SelectField";
import { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function Signup(props) {
  const dropDownOption = [
    {
      label: "Behavioral Health",
      value: "Behavioral Health",
    },
    {
      label: "Critical Care",
      value: "Critical Care",
    },
    {
      label: "Hospitalist",
      value: "Hospitalist",
    },
    {
      label: "Neuro",
      value: "Neuro",
    },
    {
      label: "Subacute Care",
      value: "Subacute Care",
    },
    {
      label: "Outpatient Services",
      value: "Outpatient Services",
    },
    {
      label: "Other Specialties ER / Inpatient TeleConsult",
      value: "Other Specialties ER / Inpatient TeleConsult",
    },
    {
      label: "End User",
      value: "End User",
    },
  ];

  const { action, handler } = props;
  const navigation = useNavigate();
  // const { result, setResult } = useState("");
  const [searchText, setSearchText] = useState("");
  const [serviceLineValue, setServiceLineValue] = useState("");
  const methods = useForm({ mode: "onDropDownChange" });

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
  });

  const submit = (data) =>
    signupUser(data).then((res) => {
      console.log("data", data);
      if (res.data.success) {
        toast.success(
          "User Signup Successfully! Waiting Approval From Administration. Once Your Account Approved You Will Get Email!"
        );
        return navigation("/login");
      } else {
        toast.error(res.data.message);
      }
    });

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const ShowPassword = (e) => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const PasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onDropDownChange = (event, value) => {
    if (value) {
      setServiceLineValue(value.value);
    } else {
      setServiceLineValue("");
    }
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={Logo} alt="Workflow" />
          <h2 className="mt-5 text-center px-3 md:px-0 text-3xl font-extrabold text-gray-900">
            Sign Up
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleSubmit(submit)}
              className="space-y-6"
              action={action}
              method=""
            >
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1">
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    autoComplete="fullName"
                    required
                    {...register("fullName", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register("email", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Service Lines
                </label>
                {/* <div className="mt-1"> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  onChange={(event, value) => onDropDownChange(event, value)}
                  options={dropDownOption}
                  sx={{ width: "100%", mr: 1, color: "#00B0CB" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register("speciality", { required: true })}
                    />
                  )}
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    required
                    {...register("password", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <div
                    className="absolute top-0 right-0 p-2.5 cursor-pointer"
                    onClickCapture={ShowPassword}
                  >
                    {values.showPassword ? (
                      <EyeIcon className="w-4 text-black" />
                    ) : (
                      <EyeOffIcon className="w-4 text-black" />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    required
                    className="h-4 w-4 mt-1 text-primary cursor-pointer focus:ring-primary border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900 cursor-pointer"
                  >
                    Creating an account means you’re okay with our
                    <a
                      href="https://www.co-source.net/terms-of-use-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-0.5 text-primary font-bold hover:underline"
                    >
                      {/* https://dev-portal.co-source.net/Terms%20of%20Use.pdf" */}
                      Terms of Service
                    </a>
                    <span>and </span>
                    <a
                      href="https://www.co-source.net/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-0.5 text-primary font-bold hover:underline"
                    >
                      {/* https://dev-portal.co-source.net/Privacy%20Policy.pdf */}
                      Privacy Policy's
                    </a>
                    .
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-primary hover:bg-info-600/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Sign up
                </button>
              </div>
              <div className="text-center text-sm">
                Already a member?
                <Link
                  to="/login"
                  className="px-0.5 text-black font-bold underline hover:underline"
                >
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
Signup.defaultProps = {
  action: "/admin/dashboard",
};
