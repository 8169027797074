import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function Neuro() {
  const [FollowUp, setFollowUp] = useState(null);
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.subacute);
  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };
  const Subacute = useCallback(
    () => [
      {
        id: "99307-10 mins-Subsequent/Follow-up SNF care, per day",
        title: "99307-10 mins-Subsequent/Follow-up SNF care, per day",
      },
      {
        id: "99308-15 mins-Subsequent/Follow-up SNF care, per  day",
        title: "99308-15 mins-Subsequent/Follow-up SNF care, per  day",
      },
      {
        id: "99309-25 mins-Subsequent/Follow-up SNF care, per  day",
        title: "99309-25 mins-Subsequent/Follow-up SNF care, per  day",
      },
      {
        id: "99310-35 mins-Subsequent/Follow-up SNF care, per  day",
        title: "99310-35 mins-Subsequent/Follow-up SNF care, per  day",
      },
    ],
    []
  );

  const { watch, register } = useFormContext();
  const follows = watch("critical_care_visit");
  const follow = useCallback(
    () => [
      { id: "Bruceville Terrace", value: "Bruceville Terrace" },
      { id: "Montefino", value: "Montefino" },
      { id: "Montefino Sacramento", value: "Montefino Sacramento" },
    ],
    []
  );

  const subacuteData = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];

  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  return (
    <div className="">
      {/* <SelectField
        mainClass="mb-6"
        options={follow()}
        value="Subacute Care Facilities"
        placeholder="Select Subacute Care Facilities"
        name="subacute"
        rules={{
          required: "Subacute Care Facilities Is Compulsory!",
          validate: (value) =>
            value !== "0" ? true : "Subacute Care Facilities Is Compulsory!",
        }}
      /> */}

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        value={value}
        onChange={(event, value) => onDropDownChange(event, value)}
        options={subacuteData}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("subacute", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Subacute Care Facilities"
          />
        )}
      />

      <RadioField
        value="Service Type"
        list={Subacute()}
        listClass="grid lg:grid-cols-2 gap-y-6 grid-cols-1 mt-3 mb-6"
        rules={{
          required: "Follow Up!",
        }}
        name="service_type"
      />
    </div>
  );
}

export default Neuro;
