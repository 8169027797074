/*
*This basically for others facilties not ER 
*/


import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function Neuro() {
  const [FollowUp, setFollowUp] = useState(null);
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.ert);
  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };
  const er = useCallback(
    () => [
      // {
      //   id: "Fairchild Medical Center",
      //   value: "Fairchild Medical Center",
      // },
      // { id: "Livingston", value: "Livingston" },
      // { id: "Lufkin", value: "Lufkin" },
      // { id: "Marshall Medical", value: "Marshall Medical" },
      // { id: "Montefino", value: "Montefino" },
      // {
      //   id: "St. Joseph (College Station)",
      //   value: "St. Joseph (College Station)",
      // },
      // { id: "St. Luke (Sugar Land)", value: "St. Luke (Sugar Land)" },
      // { id: "Vaca Valley", value: "Vaca Valley" },
      { id: "Marlette Regional Hospital", value: "Marlette Regional Hospital" },
    ],
    []
  );

  const er_counter = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];
  const initials = useCallback(
    () => [
      {
        id: "G0425 - 30 minutes - Emergency Department or Initial Inpatient",
        title: "G0425 - 30 minutes - Emergency Department or Initial Inpatient",
      },
      {
        id: "G0426 - 50 minutes - Emergency Department or Initial Inpatient",
        title: "G0426 - 50 minutes - Emergency Department or Initial Inpatient",
      },
      {
        id: "G0427 - 70 minutes or more - Emergency Department or Initial Inpatient",
        title:
          "G0427 - 70 minutes or more - Emergency Department or Initial Inpatient",
      },
    ],
    []
  );
  const followUp = useCallback(
    () => [
      {
        id: "G0406 - 15 minutes - Subsequent / Follow-up inpatient consultation",
        title:
          "G0406 - 15 minutes - Subsequent / Follow-up inpatient consultation",
      },
      {
        id: "G0407 - 25 minutes - Subsequent / Follow-up inpatient consultation",
        title:
          "G0407 - 25 minutes - Subsequent / Follow-up inpatient consultation",
      },
      {
        id: "G0408 - 35 minutes - Subsequent / Follow-up inpatient consultation",
        title:
          "G0408 - 35 minutes - Subsequent / Follow-up inpatient consultation",
      },
    ],
    []
  );
  const interprofessional_call = useCallback(
    () => [
      {
        id: "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
        title:
          "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
      },
      {
        id: "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
        title:
          "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
      },
      {
        id: "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
        title:
          "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
      },
      {
        id: "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
        title:
          "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
      },
    ],
    []
  );

  const telephonic_consultation = useCallback(
    () => [
      {
        id: "99441: Telephone E/M service; 5-10 mins",
        title: "99441: Telephone E/M service; 5-10 mins",
      },
      {
        id: "99442: Telephone E/M service; 11-20 mins",
        title: "99442: Telephone E/M service; 11-20 mins",
      },
      {
        id: "99443: Telephone E/M service, 21-30 mins",
        title: "99443: Telephone E/M service, 21-30 mins",
      },
    ],
    []
  );
  const { watch, register } = useFormContext();
  const follows = watch("other_specialties_er_visit");
  const follow = useCallback(
    () => [
      {
        id: "Initial",
        title: "Initial",
        component: (
          <RadioField
            value="Service Type"
            list={initials()}
            listClass="grid md:grid-cols-2 gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Follow Up",
        title: "Follow Up",
        component: (
          <RadioField
            value="Service Type"
            list={followUp()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Interprofessional Call",
        title: "Interprofessional Call",
        component: (
          <RadioField
            value="Service Type"
            list={interprofessional_call()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Telephonic Consultation",
        title: "Telephonic Consultation",
        component: (
          <RadioField
            value="Service Type"
            list={telephonic_consultation()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  return (
    <div className="">
      {/* <SelectField
        mainClass="mb-6"
        options={er()}
        value="Other Specialties ER / Inpatient TeleConsult"
        placeholder="Select Other Specialties ER / Inpatient TeleConsult"
        name="other_specialties_er"
        rules={{
          required:
            "Other Specialties ER / Inpatient TeleConsult Is Compulsory!",
          validate: (value) =>
            value !== "0"
              ? true
              : "Other Specialties ER / Inpatient TeleConsult Is Compulsory!",
        }}
      /> */}

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        value={value}
        onChange={(event, value) => onDropDownChange(event, value)}
        options={er_counter}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("other_specialties_er", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Other Specialties ER / Inpatient TeleConsult"
          />
        )}
      />

      <RadioField
        value="Select Other Specialties ER / Inpatient TeleConsult Visit"
        list={follow()}
        listClass="grid lg:grid-cols-2 gap-y-6 grid-cols-2 mt-3 mb-6"
        rules={{
          required: "Follow Up!",
        }}
        name="other_specialties_er_visit"
      />
      <div className="w-full mt-6">{FollowUp ? FollowUp?.component : null}</div>
    </div>
  );
}

export default Neuro;
