import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, NavLink } from "react-router-dom";
import UserMenu from "./header/UserMenu";
import { useDispatch } from "react-redux";
import { toggleLoader } from "utils/redux/action";
import Logo from "../images/logo-new.png";

const navigation = [
  { name: "Encounter", to: "/" },
  { name: "Reports", to: "/behavioralhealth" },
  // { name: "Critical Care", to: "/criticalcare" },
];

function Hero() {
  const dispatch = useDispatch();
  const clear = () => {
    localStorage.clear();
    dispatch(toggleLoader());
    return setTimeout(() => window.location.replace("/login"), 500);
  };
  return (
    <div className="sticky top-0 z-50">
      <div className="">
        <Popover as="header" className="relative">
          <div className="bg-white -800 pb-4 sticky top-0">
            <h1 className="text-primary font-bold pt-12 pb-10 px-2 lg:text-3xl md:text-3xl text-lg text-center">
              Accelerating HealthCare Solutions
            </h1>
            <nav
              className="relative max-w-6xl mx-auto h-20 flex items-center justify-between px-4 md:px-8"
              aria-label="Global"
            >
              <div className="flex-1 pb-2">
                <a target="_blank" rel="noopener noreferrer" href="https://www.rmscosource.com/">
                  <img className="md:w-36 w-32 md:-ml-3" src={Logo} alt="" />
                </a>

                {/* <h1 className="text-white font-semibold lg:text-3xl text-2xl leading-none">
                  Vee<span className="text-primary">OneCal</span>
                </h1> */}
              </div>
              <div className="flex flex-1 items-center justify-between">
                <div className="flex items-center justify-end flex-1 md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none ring-1 ring-primary">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div
                  className="hidden lg:space-x-8 space-x-4 md:flex justify-center w-full"
                  id="nav"
                >
                  {navigation.map((item, k) => (
                    <NavLink
                      key={k}
                      to={item.to}
                      className={({ isActive }) =>
                        `text-sm tracking-wide whitespace-nowrap font-medium  ${isActive
                          ? "text-primary text-lg"
                          : "text-balck hover:text-primary text-lg"
                        }`
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="hidden flex-1 justify-end md:flex md:items-center md:space-x-6">
                <UserMenu
                  value={localStorage.getItem("user_email")}
                  to="/login"
                  adminClass="hidden"
                  textClass="text-black font-normal text-xs"
                />
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed z-50 top-0 bg-grey-100 h-screen inset-x-0 p-2 transition transform origin-top md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    {/* <img className="w-16" src={Logo} alt="" /> */}
                    <h1 className="text-gray-900 font-semibold text-2xl leading-none text-darkBlue">
                     Co<span >Source</span>
                    </h1>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:bg-gray-100 focus:outline-none ring-1 ring-primary">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="space-y-1" id="nav">
                    {navigation.map((item, k) => (
                      <Popover.Button
                        className="block text-left w-full"
                        key={k}
                      >
                        <NavLink
                          to={item.to}
                          className={({ isActive }) =>
                            `block text-left w-full px-5 py-2 rounded-md text-sm font-medium transition-all ${isActive
                              ? "text-primary bg-gray-50"
                              : "text-gray-900 hover:bg-gray-50"
                            }`
                          }
                        >
                          {item.name}
                        </NavLink>
                      </Popover.Button>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <p className="text-center text-base font-medium text-gray-500">
                      <Link
                        to="/login"
                        onClick={clear}
                        className="text-gray-900 hover:underline"
                      >
                        Log out
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
}

export default Hero;
