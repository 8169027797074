import React, { useEffect, useState } from "react";
import AllEncounter from "../../components/dashboard/Tables/all-encounter";
import moment from "moment";
import FilterPopup from "../../components/FilterPopup";
import WelcomeBanner from "../../components/dashboard/WelcomeBanner";
import Dialog from "components/delete-popup";
import { DownloadIcon, TrashIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { onGetAllEncounterSheet } from "./store/actions";
import { deleteEncounterSheets, filterEncounter } from "utils/api/admin";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { useFetch } from "utils/api/usefetch";

function Encounter() {
  const [open, setOpen] = useState(false);
  const headers = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Create At",
      key: "createdAt",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Date Of Service",
      key: "date_of_service",
    },
    {
      label: "Patient Location",
      key: "patient_location",
    },
    {
      label: "Service Line",
      key: "service_line",
    },
    {
      label: "Behavioral Health Facilities",
      key: "behavioral_health",
    },
    {
      label: "Behavioral Health Visit",
      key: "behavioral_visit",
    },
    {
      label: "Critical Care",
      key: "critical_care",
    },
    {
      label: "Critical Care Visit",
      key: "critical_care_visit",
    },
    {
      label: "Other Specialties ER / Inpatient TeleConsult",
      key: "other_specialties_er",
    },
    {
      label: "Other Specialties ER / Inpatient TeleConsult Visit",
      key: "other_specialties_er_visit",
    },
    {
      label: "Neuro",
      key: "neuro",
    },
    {
      label: "Hospitalist Facilities",
      key: "hospitalist",
    },
    {
      label: "Hospitalist Visit",
      key: "hospitalist_visit",
    },
    {
      label: "Outpatient Services",
      key: "outpatient_service",
    },
    {
      label: "ERT / Transfer",
      key: "ert",
    },
    {
      label: "Subacute Care",
      key: "subacute",
    },
    {
      label: "Service Type",
      key: "service_type",
    },
    {
      label: "First Name",
      key: "first_name",
    },
    {
      label: "Last Name",
      key: "last_name",
    },
    {
      label: "Date of Birth",
      key: "dob",
    },
    {
      label: "Reason for Consult",
      key: "reason_for_consult",
    },
    {
      label: "Medical Record No",
      key: "mrn",
    },

    {
      label: "Referring Pysician",
      key: "referring_provider",
    },
  ];
  const [state, setState] = useState([]);
  const [deepCopy, setDeepCopy] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState([]);
  const [disable, setDisable] = useState(false);
  const [active, setActive] = useState(0);
  const [popup, SetPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [keywordOne, setKeywordOne] = useState("");
  const [keywordTwo, setKeywordTwo] = useState("");
  const toggle = () => SetPopup(!popup);
  const dispatch = useDispatch();
  const encounter = useSelector(({ Admin }) => Admin?.sheets);
  const [totalFitler, setTotalFilter] = useState([]);
  const [filters, setFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(encounter) !== JSON.stringify(state)) {
      setState(encounter);
    }
    setDeepCopy(encounter);
  }, [encounter]);

  const fetchRecords = () => {
    setLoader(true);
    return new Promise((resolve, reject) => {
      return resolve(dispatch(onGetAllEncounterSheet()));
    })
      .then(() => {
        return setLoader(false);
      })
      .catch(() => {
        return setLoader(false);
      });
  };
  const deleteRecords = () => {
    return deleteEncounterSheets(selected?.map((v) => v?.original?.id)).then(
      () => {
        toast.success("Sheets Deleted!");
        setOpen(false);
        return fetchRecords();
      }
    );
  };
  const findFilter = async () => {
    setLoader(true);
    const payload = await filterEncounter(filters);
    setLoader(false);
    const data = payload?.data?.Data;
    setState(data);
  };
  useEffect(() => {
    if (Object.keys(filters)?.length > 0) findFilter();
    else fetchRecords();
  }, [filters]);
  const tabs = [
    { name: "All" },
    { name: "Last 24 Hours", key: "24" },
    { name: "Behavioral Health", key: "Behavioral Health" },
    { name: "Critical Care", key: "Critical Care" },
    {
      name: "Other Specialties ER / Inpatient TeleConsult",
      key: "Other Specialties ER / Inpatient TeleConsult",
    },
    { name: "Neuro", key: "Neuro" },
    { name: "Subacute Care", key: "Subacute Care" },
    { name: "Hospitalist", key: "Hospitalist" },
    { name: "Outpatient Services", key: "Outpatient Services" },
  ];
  const filter = async (e) => {
    setActive(e?.index);
    if (e?.key === "24") {
      setLoader(true);
      const request = await useFetch.get("admin/latest_encounters");
      const payload = request?.data?.Data;
      setState(payload);
      setLoader(false);
      return payload;
    }
    if (e?.key) {
      setLoader(true);
      const request = await filterEncounter({ service_line: e?.key });
      const payload = request?.data?.Data;
      setState(payload);
      setLoader(false);
      return payload;
    } else {
      setState(deepCopy);
    }
  };
  const deleteFilter = (key) => {
    const payload = totalFitler.find((v, k) => key === k);
    const filtered = totalFitler.filter((v, k) => key !== k);
    const filter = filters;
    delete filter[payload?.filed];
    setFilters({ ...filter });
    setTotalFilter(filtered);
  };
  const search = async () => {
    if (selectedField && selectedFilter && keywordOne) {
      const newArray = totalFitler.filter((v) => v.filed !== selectedField);
      newArray.push({
        filed: selectedField,
        selectedFilter,
        search: keywordOne,
      });
      setTotalFilter(newArray);
      switch (selectedFilter) {
        case "None": {
          setState(deepCopy);
          setSelectedField(null);
          setSelectedFilter(null);
          setKeywordOne("");
          setKeywordTwo("");
          break;
        }
        case "Equal": {
          if (keywordOne === "") {
            setState(deepCopy);
            break;
          } else {
            const body = {
              [selectedField]: {
                condition: "inequalities",
                date: keywordOne,
                operator: "=",
              }
            };
            const filter = {
              ...filters,
              ...body,
            };
            setFilters(filter);

            break;
          }
        }
        case "Contains": {
          if (keywordOne === "") {
            setState(deepCopy);
            break;
          } else {
            const body = {
              [selectedField]: keywordOne,
            };
            const filter = {
              ...filters,
              ...body,
            };
            setFilters(filter);
          }
          break;
        }
        case "Between": {
          const body = {
            [selectedField]: {
              condition: "between",
              starting_date: keywordOne,
              ending_date: keywordTwo,
            },
          };
          const filter = {
            ...filters,
            ...body,
          };
          setFilters(filter);
          break;
        }
        case "After": {
          const body = {
            [selectedField]: {
              condition: "inequalities",
              date: keywordOne,
              operator: ">",
            },
          };

          const filter = {
            ...filters,
            ...body,
          };
          setFilters(filter);
          break;
        }
        case "Before": {
          const body = {
            [selectedField]: {
              condition: "inequalities",
              date: keywordOne,
              operator: "<",
            },
          };
          const filter = {
            ...filters,
            ...body,
          };
          setFilters(filter);

          break;
        }
        default: {
        }
      }
      toggle();
      setSelectedField(null);
      setSelectedFilter(null);
      setKeywordOne("");
      setKeywordTwo("");
    }
  };
  return (
    <div>
      <Dialog
        title="Delete Encounter Sheet?"
        description={`Are you sure to delete ${selected.length} Encounters?`}
        open={open}
        setOpen={setOpen}
        callback={deleteRecords}
      />
      <WelcomeBanner title="Encounter Sheet" />

      <div className="flex items-center md:space-x-3 w-full md:justify-end justify-between 2xl:w-auto">
        <button
          onClick={toggle}
          className="relative inline-flex items-center px-4 py-2.5 border border-gray-300 text-xs leading-4 font-semibold rounded text-gray-700 bg-white hover:bg-white/90 whitespace-nowrap"
        >
          {totalFitler.length > 0 ? totalFitler.length : ""} Filter
          <svg className="w-3 h-3 fill-current ml-3" viewBox="0 0 16 16">
            <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
          </svg>
        </button>
        <button
          disabled={disable}
          onClick={() => setOpen(!open)}
          className={`${
            // disable ?
            selected?.length > 0
              ? "opacity-100"
              : "opacity-50 cursor-not-allowed disabled:cursor-not-allowed"
            // : "opacity-50 cursor-not-allowed disabled:cursor-not-allowed"
          } inline-flex bg-red-600 hover:bg-red-700 items-center px-4 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white focus:outline-none whitespace-nowrap`}
        >
          {selected?.length} Delete <TrashIcon className="w-5 ml-2" />
        </button>
        <CSVLink
          filename={"Encounter-Sheet.csv"}
          data={
            selected?.length > 0
              ? selected?.map((v) => ({
                  ...v?.original,
                  createdAt: moment(v?.createdAt).format("MM/DD/YYYY"),
                  date_of_service: moment(v?.date_of_service).format(
                    "MM/DD/YYYY"
                  ),
                  dob: moment(v?.dob).format("MM/DD/YYYY"),
                }))
              : state?.map((v) => ({
                  ...v,
                  createdAt: moment(v?.createdAt).format("MM/DD/YYYY"),
                  date_of_service: moment(v?.date_of_service).format(
                    "MM/DD/YYYY"
                  ),
                  dob: moment(v?.dob).format("MM/DD/YYYY"),
                }))
          }
          headers={headers}
        >
          <button className="inline-flex whitespace-nowrap items-center md:px-4 px-3 py-2 border border-transparent text-xs leading-4 font-semibold rounded shadow-sm text-white bg-primary hover:bg-info-600/90 focus:outline-none">
            Export Data <DownloadIcon className="w-5 ml-2" />
          </button>
        </CSVLink>
      </div>
      <div className="flex items-center overflow-x-auto py-3 lg:py-2">
        {tabs?.map((v, k) => {
          return (
            <button
              key={k}
              onClick={filter.bind(this, { ...v, index: k })}
              className={`mx-0.5 items-center whitespace-nowrap px-1.5 py-2 border-b-2 border-transparent md:text-sm text-xs leading-4 hover:text-black focus:text-black hover:border-primary focus:border-primary transition-all focus:outline-none ${
                k === active ? "border-primary font-semibold" : "font-light"
              }`}
            >
              {v.name}
            </button>
          );
        })}
      </div>
      <AllEncounter setSelected={setSelected} loader={loader} state={state} />
      <FilterPopup
        setSelectedFilter={setSelectedFilter}
        open={popup}
        toggleOpen={toggle}
        selectedFilter={selectedFilter}
        setSelectedField={setSelectedField}
        selectedField={selectedField}
        keywordOne={keywordOne}
        setKeywordOne={setKeywordOne}
        keywordTwo={keywordTwo}
        setKeywordTwo={setKeywordTwo}
        search={search}
        total={totalFitler}
        deleteFilter={deleteFilter}
      />
    </div>
  );
}

export default React.memo(Encounter);
