import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../utils/Transition";

import UserAvatar from "../../images/avatar.jpeg";
import { toggleLoader } from "utils/redux/action";
import { useDispatch } from "react-redux";

function UserMenu({ value, to, adminClass, textClass, path }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const clear = () => {
    localStorage.clear();
    setDropdownOpen(false);
    dispatch(toggleLoader());
    return setTimeout(() => window.location.replace(to), 500);
  };
  // close on click outside

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className="w-8 h-8 rounded-full ml-4"
          src={UserAvatar}
          width="32"
          height="32"
          alt="User"
        />
        <div className="flex items-center truncate">
          <span className={`${textClass} truncate ml-2 text-xs font-medium`}>
            {value}
          </span>
          <svg
            className="w-3 h-3 shrink-0 ml-1 fill-current text-gray-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-40 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div
            className={`${adminClass} pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200`}
          >
            <div className="text-sm text-gray-500">Administrator</div>
          </div>
          <ul className="w-40">
            <li className="w-full">
              <Link
                className="font-medium text-sm transition-all hover:text-primary flex items-center py-1 px-3"
                to={to}
                onClick={clear}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}
UserMenu.defaultProps = {
  value: "Administrator",
  to: "/login/admin",
  textClass: "group-hover:text-gray-800",
};
export default UserMenu;
