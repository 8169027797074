import { LOGIN_ADMIN } from "pages/Admin/store/actions";
import { USER_LOGIN } from "pages/Landing/store/action";
import { LOGIN, TOKEN_VERIFY } from "../store/action";
const init = {
  auth: localStorage.getItem("Access_Token") ? true : false,
  user: null,
  tokens: null,
};
const Reducer = (state = init, action) => {
  switch (action?.type) {
    case LOGIN_ADMIN: {
      return {
        ...state,
        auth: true,
      };
    }
    case USER_LOGIN: {
      return {
        ...state,
        auth: true,
        user: action?.payload,
      };
    }
    case TOKEN_VERIFY: {
      return {
        ...state,
        ...action?.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default Reducer;
