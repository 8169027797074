import { toast } from "react-toastify";
import { useFetch } from "utils/api/usefetch";
//Admin API'S
export const LoginAdmin = async (loginData) => {
  return useFetch
    .post("admin/login", loginData)
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const getDoctors = async () => {
  return useFetch
    .get("admin/get_doctors")
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const getPendingRequest = async () => {
  return useFetch
    .get("admin/pending_requests")
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const getEncounterSheet = async (options = {}) => {
  return useFetch
    .get("admin/get_encounters", options)
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const getEncounterSheetsLast24Hours = async () => {
  return useFetch
    .get("admin/latest_encounters")
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const deleteEncounterSheets = (payload) => {
  return useFetch
    .delete("admin/delete_encounters", {
      data: {
        selectedArray: payload,
      },
    })
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const deleteDoctors = (payload) => {
  return useFetch
    .delete("admin/delete_member", {
      data: {
        uId: payload,
      },
    })
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const acceptMember = (id) => {
  return useFetch
    .get(`admin/accept_request/${id}`)
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const rejectMember = (id) => {
  return useFetch
    .get(`admin/reject_request/${id}`)
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const filterEncounter = (payload) => {
  return useFetch
    .post("admin/filtered_encounters", {
      ...payload,
    })
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const assignRole = (payload) => {
  return useFetch
    .post("admin/assign_role", {
      email: localStorage.getItem("user_email"),
      ...payload,
    })
    .catch((e) => toast.error("Something Went Wrong On Server"));
};
export const adminForgetPassword = (email) => {
  return useFetch
    .post("admin/forgot_password", { email })
    .catch(() => toast.error("Something went wrong on server"));
};
export const adminResetPasssword = (payload, id) => {
  return useFetch
    .post(`admin/reset/${id}`, payload)
    .catch(() => toast.error("Something went wrong on server"));
};
