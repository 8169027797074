import { LOADER } from "../action";

const init = {
  loader: false,
};
const Reducer = (state = init, action) => {
  switch (action?.type) {
    case LOADER: {
      return {
        ...state,
        loader: !state.loader,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default Reducer;
