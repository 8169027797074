import { useDispatch } from "react-redux";
import Login from "../../components/Login";
import { onUserLogin } from "./store/action";
export default function AdminLogin() {
  const dispatch = useDispatch();
  const loginHandler = (data) => {
    return dispatch(onUserLogin(data));
  };
  return <Login adminText="" forgotLink="/reset" handler={loginHandler} />;
}
