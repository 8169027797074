import React, { useMemo } from "react";
import Table from "../../table";
import moment from "moment";
function AllEncounter({ state, loader, setSelected }) {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: ({ row }) => {
          return <>{row?.original?.id}</>;
        },
        minWidth: 50,
        maxWidth: 70,
      },
      {
        Header: "Create At",
        accessor: "createdAt",
        id: "createdAt",
        Cell: ({ row }) => {
          return moment(row?.original?.createdAt)?.format("MM/DD/yyyy");
        },
      },
      {
        Header: "Email",
        accessor: "email",
        minWidth: 220,
        id: "email",
      },
      {
        Header: "Date Of Service",
        accessor: "date_of_service",
        Cell: ({ row }) => {
          return moment(row?.original?.date_of_service)?.format("MM/DD/yyyy");
        },
      },
      {
        Header: "Patient Location",
        accessor: "patient_location",
        minWidth: 200,
        id: "patient_location",
      },
      {
        Header: "Service Line",
        accessor: "service_line",
        minWidth: 160,
        id: "service_line",
      },
      {
        Header: "Behavioral Health Facilities",
        accessor: "behavioral_health",
        minWidth: 230,
        id: "behavioral_health",
      },
      {
        Header: "Behavioral Health Visit",
        accessor: "behavioral_visit",
        minWidth: 200,
        id: "behavioral_visit",
      },
      {
        Header: "Critical Care",
        accessor: "critical_care",
        id: "critical_care",
      },
      {
        Header: "Critical Care Visit",
        accessor: "critical_care_visit",
        minWidth: 170,
        id: "critical_care_visit",
      },
      {
        Header: "Other Specialties ER / Inpatient TeleConsult",
        accessor: "other_specialties_er",
        id: "other_specialties_er",
        minWidth: 300,
      },
      {
        Header: "Other Specialties ER / Inpatient TeleConsult Visit",
        accessor: "other_specialties_er_visit",
        id: "other_specialties_er_visit",
        minWidth: 330,
      },
      {
        Header: "Neuro",
        accessor: "neuro",
        id: "neuro",
      },
      {
        Header: "Hospitalist Facilities",
        accessor: "hospitalist",
        id: "hospitalist",
        minWidth: 180,
      },
      {
        Header: "Hospitalist Visit",
        accessor: "hospitalist_visit",
        id: "hospitalist_visit",
      },
      {
        Header: "Outpatient Services",
        accessor: "out_patient",
        id: "out_patient",
        minWidth: 180,
      },
      {
        Header: "ERT / Transfer",
        accessor: "ert",
        id: "ert",
      },
      {
        Header: "Subacute Care",
        accessor: "subacute",
        id: "subacute",
      },
      {
        Header: "Service Type",
        accessor: "service_type",
        id: "service_type",
        minWidth: 320,
      },
      {
        Header: "First Name",
        accessor: "first_name",
        id: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        id: "last_name",
      },
      {
        Header: "Date of Birth",
        Cell: ({ row }) => {
          return moment(row?.original?.dob)?.format("MM/DD/yyyy");
        },
      },
      {
        Header: "Reason for Consult",
        accessor: "reason_for_consult",
        id: "reason_for_consult",
        minWidth: 170,
      },
      {
        Header: "Medical Record No",
        accessor: "mrn",
        id: "mrn",
        minWidth: 170,
      },

      {
        Header: "Referring Pysician",
        accessor: "referring_provider",
        id: "referring_provider",
        minWidth: 170,
      },
    ],
    []
  );
  return (
    <Table
      loader={loader}
      columns={columns}
      data={state}
      bodyClass="py-2.5 truncate"
      setSelected={setSelected}
    />
  );
}

export default React.memo(AllEncounter);
