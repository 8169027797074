import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HomeIcon, CalendarIcon, BookOpenIcon } from "@heroicons/react/outline";
import Logo from "../images/logo-new.png";
import LogoMini from "../images/logo-mini.png";

function Sidebar({ className, sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);
  const navigations = [
    {
      id: "1",
      name: "Dashboard",
      to: "/admin/dashboard",
      icon: HomeIcon,
    },
    {
      id: "2",
      to: "/admin/encounter",
      name: "Encounter Sheet",
      icon: CalendarIcon,
    },
    {
      id: "3",
      to: "/admin/site_member",
      name: "Site Members",
      icon: BookOpenIcon,
    },
  ];
  return (
    <div className={className}>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 shrink-0 bg-black  p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        <div className="flex justify-between mb-10 pt-2 pr-3 sm:px-2">
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          <NavLink
            to="/admin/dashboard"
            className="flex items-cneter justify-center w-full md:pr-2"
          >
            {sidebarExpanded === true ? (
              <img src={Logo} className={`w-36 mx-auto `} alt="" />
            ) : (
              <img src={LogoMini} className={`w-10 mx-auto `} alt="" />
            )}
          </NavLink>
        </div>

        <div className="space-y-8">
          <div>
            <h3 className="text-xs uppercase text-white font-semibold pl-3">
              <span className="lg:hidden lg:sidebar-expanded:block">Pages</span>
            </h3>
            <ul className="mt-3">
              {navigations?.map((v, k) => {
                return (
                  <NavLink
                    to={v.to}
                    key={k}
                    className={({ isActive }) =>
                      `block truncate transition duration-150 my-2 rounded-md ${
                        isActive
                          ? "text-primary bg-gray-900"
                          : "text-white hover:text-primary"
                      }`
                    }
                  >
                    <li className={`px-3 py-3 mb-1.5 last:mb-0 `}>
                      <div className="flex items-center">
                        <div>
                          <v.icon className={` h-5 w-5`} aria-hidden="true" />
                        </div>
                        <span
                          className={` text-sm font-medium pl-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                        >
                          {v.name}
                        </span>
                      </div>
                    </li>
                  </NavLink>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="pt-3 hidden lg:inline-flex justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-white"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-white" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
