import React from "react";
import { Link } from "react-router-dom";

function DashboardCard({ to, title, totalData }) {
  return (
    <Link to={to} className="w-full">
      <div className="flex flex-col px-5 py-5 items-center bg-white shadow-lg hover:shadow-none transition-all rounded-sm border border-gray-200">
        <div className="text-3xl font-bold text-gray-800 mr-2">{totalData}</div>
        <h2 className="md:text-sm text-base font-semibold text-gray-800 mb-2">
          {title}
        </h2>
      </div>
    </Link>
  );
}

export default DashboardCard;
