import { toast } from "react-toastify";
import { userLogin } from "utils/api/user";

//Constant
export const USER_LOGIN = "USER LOGIN [VEEONECAL]";
//Actions
export const onUserLogin = (payload) => {
  return (dispatch) => {
    return userLogin(payload).then((res) => {
      if (res.data?.success) {
        localStorage.setItem("user_email", payload?.email);
        localStorage.setItem("Access_Token", res?.data?.Data?.access_token);
        localStorage.setItem("role", res?.data?.Data?.role);
        localStorage.setItem("speciality", res?.data?.Data?.data?.speciality);

        toast.success("Login Successfully!");
        return dispatch({
          type: USER_LOGIN,
          payload: {
            email: payload?.email,
            speciality:res?.data?.Data?.data?.speciality
          },
        });
      } else {
        toast.error(res?.data?.message);
      }
    });
  };
};
