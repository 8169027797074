import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import Logo from "../images/logo-new.png";
// import InputPassword from "./password";
export default function Login(props) {
  const { forgotLink, action, adminText, handler, bottomClass } = props;
  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
  });
  const submit = (data) => handler(data);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const ShowPassword = (e) => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const PasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={Logo} alt="Workflow" />
          <h2 className="mt-5 text-center text-2xl font-bold text-gray-900">
            {adminText}
          </h2>
          <h2 className="mt-5 text-center px-5 md:px-0 text-3xl font-extrabold text-gray-900">
            Sign In
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleSubmit(submit)}
              className="space-y-6"
              action={action}
              method=""
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register("email", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    required
                    {...register("password", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <div
                    className="absolute top-0 right-0 p-2.5 cursor-pointer"
                    onClickCapture={ShowPassword}
                  >
                    {values.showPassword ? (
                      <EyeIcon className="w-4 text-black" />
                    ) : (
                      <EyeOffIcon className="w-4 text-black" />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end">
                {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-primary cursor-pointer focus:ring-primary border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900 cursor-pointer"
                  >
                    Remember me
                  </label>
                </div> */}

                <div className="text-sm">
                  <Link
                    to={forgotLink}
                    className="font-medium text-primary hover:text-primary"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-info-600/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Sign in
                </button>
              </div>
              <div className={`${bottomClass} text-center text-sm`}>
                Create a new account?
                <Link
                  to="/signup"
                  className="px-0.5 text-primary hover:underline"
                >
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
Login.defaultProps = {
  action: "/admin/dashboard",
  adminText: "CoSource Admin Portal",
};
