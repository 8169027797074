import { toast } from "react-toastify";
import { useFetch } from "./usefetch";

//Constant
export const userLogin = (payload) => {
  return useFetch
    .post("doctor/login", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const searchPatients = (payload) => {
  return useFetch
    .post("doctor/search_patient", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const createEncounter = (payload) => {
  return useFetch
    .post("doctor/create_encounter", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const getServiceLine = (payload) => {
  return useFetch
    .post("doctor/get_service_line", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const updateEncounter = (payload) => {
  return useFetch
    .put("doctor/update_encounter", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const signupUser = (payload) => {
  return useFetch
    .post("doctor/registration", payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const userForgetPassword = (email) => {
  return useFetch
    .post("doctor/forgot_password", { email })
    .catch(() => toast.error("Something went wrong on server"));
};
export const userResetPasssword = (payload, id) => {
  return useFetch
    .post(`doctor/reset/${id}`, payload)
    .catch(() => toast.error("Something went wrong on server"));
};
export const deleteEncounter = (email, id) => {
  return useFetch
    .delete(`/doctor/encounter/${email}/${id}`)
    .catch(() => toast.error("Something went wrong on serve"));
};
