import moment from "moment";
import React from "react";
import Modal from "./Modal";
function FilterPopup(props) {
  const { open, toggleOpen, state } = props;

  return (
    <Modal
      open={open}
      close={toggleOpen}
      mainClass="bg-white rounded text-left overflow-hidden shadow-xl transform transition-all w-full max-w-2xl"
      className="p-2"
      content={
        <div className="w-full pb-5 space-y-6 md:px-5 px-3">
          <label className="block my-3 text-lg md:text-xl font-medium text-dark">
            Encounter Review
          </label>
          <div className="grid md:gap-3 gap-y-3">
            {state &&
              Object.keys(state).map((v, k) => {
                return v !== "certify"
                  ? (console.log(v),
                    (
                      <div className="grid grid-cols-2" key={k}>
                        <h1 className="md:text-sm text-xs capitalize font-semibold">
                          {v?.split("_")?.map((val) => `${val} `)}
                        </h1>
                        <h2
                          className={`${
                            v !== "email" ? "capitalize" : null
                          } md:text-sm text-xs text-gray-600`}
                        >
                          {v === "dob" || v === "date_of_service"
                            ? state[v]
                              ? moment(state[v]).format("MM/DD/YYYY")
                              : "--/--/----"
                            : state[v]}
                        </h2>
                      </div>
                    ))
                  : null;
              })}
          </div>
        </div>
      }
      action={
        <div className="flex justify-end mt-8 pt-2 border-t">
          <button
            onClick={toggleOpen}
            className="inline-flex items-center md:px-4 px-3 md:py-2 py-2 border border-transparent text-xs md:text-sm leading-4 font-semibold rounded shadow-sm text-black bg-primary hover:bg-info-600/90 focus:outline-none cursor-pointer"
          >
            Close
          </button>
        </div>
      }
    />
  );
}

export default React.memo(FilterPopup);
