import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function Hospitalist() {
  const [FollowUp, setFollowUp] = useState(null);
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.hospitalist);
  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };
  const Hospitalist = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];

  const discharge = useCallback(
    () => [
      {
        id: "99238 - Hospital discharge day management, 30 minutes or less.",
        title: "99238 - Hospital discharge day management, 30 minutes or less.",
      },
      {
        id: "99239 - Hospital discharge day management, more than 30 minutes.",
        title:
          "99239 - Hospital discharge day management, more than 30 minutes.",
      },
      {
        id: "99217 - Observation care discharge day management.",
        title: "99217 - Observation care discharge day management.",
      },
    ],
    []
  );
  const followUp = useCallback(
    () => [
      {
        id: "99231 - Subsequent hospital care, per day, for the evaluation and management of a patient, 15 minutes.",
        title:
          "99231 - Subsequent hospital care, per day, for the evaluation and management of a patient, 15 minutes.",
      },
      {
        id: "99232 - Subsequent hospital care, per day, for the evaluation and management of a patient, 25 minutes.",
        title:
          "99232 - Subsequent hospital care, per day, for the evaluation and management of a patient, 25 minutes.",
      },
      {
        id: "99233 - Subsequent hospital care, per day, for the evaluation and management of a patient, 35 minutes.",
        title:
          "99233 - Subsequent hospital care, per day, for the evaluation and management of a patient, 35 minutes.",
      },
    ],
    []
  );
  const initials = useCallback(
    () => [
      {
        id: "99221 - Initial hospital care, per day, for the evaluation and management of a patient, 30 minutes.",
        title:
          "99221 - Initial hospital care, per day, for the evaluation and management of a patient, 30 minutes.",
      },
      {
        id: "99222 - Initial hospital care, per day, for the evaluation and management of a patient, 50 minutes.",
        title:
          "99222 - Initial hospital care, per day, for the evaluation and management of a patient, 50 minutes.",
      },
      {
        id: "99223 - Initial hospital care, per day, for the evaluation and management of a patient, 70 minutes.",
        title:
          "99223 - Initial hospital care, per day, for the evaluation and management of a patient, 70 minutes.",
      },
      {
        id: "99218 - Initial observation care, per day, for the evaluation and management of a patient, 30 minutes.",
        title:
          "99218 - Initial observation care, per day, for the evaluation and management of a patient, 30 minutes.",
      },
      {
        id: "99219 - Initial observation care, per day, for the evaluation and management of a patient, 50 minutes.",
        title:
          "99219 - Initial observation care, per day, for the evaluation and management of a patient, 50 minutes.",
      },
      {
        id: "99220 - Initial observation care, per day, for the evaluation and management of a patient, 70 minutes.",
        title:
          "99220 - Initial observation care, per day, for the evaluation and management of a patient, 70 minutes.",
      },
      {
        id: "99234 -  E/M Observ or Inpt Hosp Care - Admit and Dischg, 40 mins.",
        title:
          "99234 -  E/M Observ or Inpt Hosp Care - Admit and Dischg, 40 mins.",
      },
      {
        id: "99235 - E/M Observ or Inpt Hosp Care - Admit and Dischg,  50 mins.",
        title:
          "99235 - E/M Observ or Inpt Hosp Care - Admit and Dischg,  50 mins.",
      },
      {
        id: "99236 - E/M Observ or Inpt Hosp Care - Admit and Dischg, 55 mins.",
        title:
          "99236 - E/M Observ or Inpt Hosp Care - Admit and Dischg, 55 mins.",
      },
    ],
    []
  );
  const interprofessional_call = useCallback(
    () => [
      {
        id: "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
        title:
          "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
      },
      {
        id: "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
        title:
          "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
      },
      {
        id: "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
        title:
          "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
      },
      {
        id: "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
        title:
          "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
      },
    ],
    []
  );

  const telephonic_consultation = useCallback(
    () => [
      {
        id: "99441: Telephone E/M service; 5-10 mins",
        title: "99441: Telephone E/M service; 5-10 mins",
      },
      {
        id: "99442: Telephone E/M service; 11-20 mins",
        title: "99442: Telephone E/M service; 11-20 mins",
      },
      {
        id: "99443: Telephone E/M service, 21-30 mins",
        title: "99443: Telephone E/M service, 21-30 mins",
      },
    ],
    []
  );
  const { watch, register } = useFormContext();
  const follows = watch("hospitalist_visit");
  const follow = useCallback(
    () => [
      {
        id: "Initial",
        title: "Initial",
        component: (
          <RadioField
            value="Service Type"
            list={initials()}
            listClass="grid grid-cols-1 gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Follow Up",
        title: "Follow Up",
        component: (
          <RadioField
            value="Service Type"
            list={followUp()}
            listClass="grid grid-cols-1 gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Discharge",
        title: "Discharge",
        component: (
          <RadioField
            value="Service Type"
            list={discharge()}
            listClass="grid md:grid-cols-2 gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Interprofessional Call",
        title: "Interprofessional Call",
        component: (
          <RadioField
            value="Service Type"
            list={interprofessional_call()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Telephonic Consultation",
        title: "Telephonic Consultation",
        component: (
          <RadioField
            value="Service Type"
            list={telephonic_consultation()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  return (
    <div className="">
      {/* <SelectField
        mainClass="mb-6"
        options={Hospitalist()}
        value="Hospitalist Facilities"
        placeholder="Select Hospitalist Facilities"
        name="hospitalist"
        rules={{
          required: "Hospitalist Facilities Is Compulsory!",
          validate: (value) =>
            value !== "0" ? true : "Hospitalist Facilities Is Compulsory!",
        }}
      /> */}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        value={value}
        onChange={(event, value) => onDropDownChange(event, value)}
        options={Hospitalist}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("hospitalist", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Hospitalist Facilities"
          />
        )}
      />

      <RadioField
        value="Hospitalist Visit"
        list={follow()}
        listClass="grid md:grid-cols-3 lg:grid-cols-4 gap-y-6 grid-cols-2 mt-3"
        rules={{
          required: "Follow Up!",
        }}
        name="hospitalist_visit"
      />
      <div className="w-full mt-6">{FollowUp ? FollowUp?.component : null}</div>
    </div>
  );
}

export default React.memo(Hospitalist);
