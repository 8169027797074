import { useState } from "react";
import { useForm } from "react-hook-form";
import Logo from "../images/logo.png";
export default function Forgot(props) {
  const { action, adminText, reset } = props;
  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
  });
  const submit = (data) => {
    return reset(data?.email);
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={Logo} alt="Workflow" />
          <h2 className="mt-5 text-center text-2xl font-bold text-gray-900">
            {adminText}
          </h2>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
          <p className="text-center pt-2">Please enter your email address</p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleSubmit(submit)}
              className="space-y-6"
              action={action}
              method=""
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...register("email", { required: true })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-info-600/90 hover:bg-info-600/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Send Confirmatoin
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
Forgot.defaultProps = {
  action: "/create-new-password",
  adminText: "CoSource Admin Portal",
};
