import { useSelector } from "react-redux";
import { Navigate, useLocation, Routes } from "react-router-dom";
const PrivateRoute = ({ children, roles }) => {
  let location = useLocation();
  const { auth } = useSelector(({ Authentication }) => Authentication);
  const userHasRequiredRole = localStorage.getItem("user_email");
  if (!auth) {
    return children;
  }

  if (auth && !userHasRequiredRole) {
    return <Navigate to="/admin/dashboard" state={{ from: location }} />;
  } else {
    return <Navigate to="/" state={{ from: location }} />;
  }
};
export default PrivateRoute;
