import React, { useEffect } from "react";
import {
  useTable,
  useRowSelect,
  useFlexLayout,
  usePagination,
} from "react-table";
import { useFetch } from "utils/api/usefetch";
function Tables({
  columns,
  data,
  tableClass,
  headClass,
  bodyClass,
  loader,
  setSelected,
  theadClass,
  mainClass,
  trClass,
  headtrClass,
  notChecked,
  rowClick,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data ? data : [],
    },
    useFlexLayout,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        [
          !notChecked
            ? {
                id: "selection",
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                  <div className="w-full flex items-center justify-center">
                    <IndeterminateCheckbox
                      className="text-primary focus:ring-0 w-4 h-4"
                      {...getToggleAllPageRowsSelectedProps()}
                    />
                  </div>
                ),
                Cell: ({ row }) => (
                  <div className="w-full flex items-center justify-center">
                    <IndeterminateCheckbox
                      className="text-primary focus:ring-0 w-4 h-4 rounded"
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
                width: 50,
              }
            : null,
          ...columns,
        ].filter((e) => e !== null)
      );
    }
  );
  useEffect(() => {
    if (selectedFlatRows) {
      if (setSelected) setSelected(selectedFlatRows);
    }
  }, [selectedFlatRows, setSelected]);
  useEffect(() => {
    setPageSize(50);
  }, []);
  return (
    <>
      <div className={`${mainClass} bg-white`}>
        <div className={`overflow-auto h-2/5 bg-white`}>
          {loader ? (
            <div className="w-full h-[500px] flex items-center justify-center">
              <img className="w-20" src="/loader.gif" alt="" />
            </div>
          ) : (
            <table
              className={`${tableClass} table-auto w-full h-full`}
              {...getTableProps()}
              style={{ maxWidth: "100%" }}
            >
              <>
                <thead className={`${theadClass}`}>
                  {headerGroups.map((headerGroup, k) => (
                    <tr
                      key={k}
                      className={`${headtrClass}`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, j) => (
                        <th
                          key={j}
                          className={`${headClass} px-4`}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  className="text-sm divide-y divide-gray-300"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={rowClick.bind(this, row)}
                        key={i}
                        className={`${
                          i % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } ${trClass}`}
                      >
                        {row.cells.map((cell, a) => {
                          return (
                            <td
                              key={a}
                              className={`${bodyClass} px-4 flex items-center`}
                              {...cell.getCellProps()}
                            >
                              <div className="truncate">
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </>
            </table>
          )}
        </div>
        <nav
          className="bg-white px-4 py-3 md:flex items-center justify-between border-t border-gray-200 sm:px-6"
          aria-label="Pagination"
        >
          <div className="flex justify-start items-center space-x-3">
            <p className="text-xs text-gray-700 whitespace-nowrap">
              Showing {pageIndex + 1} of {pageOptions.length}
            </p>
          </div>
          <div className="flex-1 flex justify-between md:justify-end pt-3 md:pt-0">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 cursor-pointer text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 cursor-pointer text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}
Tables.defaultProps = {
  headClass: "text-left md:py-3.5 py-2.5 font-semibold text-black-400 text-sm",
  bodyClass: "py-4 text-black-100 md:text-sm text-xs",
  mainClass: "shadow-lg rounded border border-gray-200",
  tableClass: "divide-y divide-gray-300",
  theadClass:
    "text-sm sticky top-0 bg-white font-semibold text-black-400 bg-info-200/40",
  headtrClass: "divide-x divide-gray-300",
  trClass: "divide-x divide-gray-300 hover:bg-gray-50",
  rowClick: (row) => console.log("ROW : ", row),
};
export default React.memo(Tables);
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
