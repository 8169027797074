import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo-new.png";
function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-6xl mx-auto py-12 px-4 md:px-8 lg:py-16">
        <img className="md:w-48 mx-auto w-32" src={Logo} alt="" />
        <h1 className="pt-7 text-black font-medium lg:text-3xl text-2xl leading-none text-center">
          Tel: 855-676-8326
        </h1>
      </div>
      <div className="bg-primary py-0.5 font-sans">
        <div className="max-w-6xl w-full px-4 mx-auto py-4 md:flex justify-between">
          <p className="text-black text-sm font-light text-center">
            &copy; 2023{" "}
            <Link to="/" className="text-black hover:underline">
            Receivable Management Solutions, LLC.
            </Link>
            All right reserved.
          </p>
          <p className="text-black-400 text-sm font-light text-center pt-2 md:pt-0">
          113 South Monroe Street, 1st Floor - #146, Tallahassee, FL 32301          </p>
          
        </div>
      </div>
    </footer>
  );
}

export default Footer;
