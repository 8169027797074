import React, { useCallback, useEffect, useState } from "react";
import SelectField from "components/SelectField";
import RadioField from "components/RadioField";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

function BehavioralHealth() {
  let payload = localStorage.getItem("update");
  payload = payload ? JSON.parse(payload) : null;
  const [value, setValue] = useState(payload?.critical_care);
  const [FollowUp, setFollowUp] = useState(null);

  const onDropDownChange = (event, value) => {
    if (value) {
      setValue(value.value);
    } else {
      setValue(null);
    }
  };

  const behHealth = [
    { label: "Clinic A", value: "Clinic A" },
    { label: "Clinic B", value: "Clinic B" },
    { label: "Clinic C", value: "Clinic C" },
    { label: "Clinic D", value: "Clinic D" },
  ];

  const followUp = useCallback(
    () => [
      {
        id: "Subsequent / Follow-Up Visit - G0509 - 50 minutes- Telehealth consultation, critical care",
        title:
          "Subsequent / Follow-Up Visit - G0509 - 50 minutes- Telehealth consultation, critical care",
      },
      {
        id: "Subsequent / Follow-up -G0408 - 35 minutes - inpatient consultation",
        title:
          "Subsequent / Follow-up -G0408 - 35 minutes - inpatient consultation",
      },
      {
        id: "Subsequent / Follow-up - G0407 - 25 minutes - inpatient consultation",
        title:
          "Subsequent / Follow-up - G0407 - 25 minutes - inpatient consultation",
      },
      {
        id: "Subsequent / Follow-up - G0406 - 15 minutes - inpatient consultation",
        title:
          "Subsequent / Follow-up - G0406 - 15 minutes - inpatient consultation",
      },
    ],
    []
  );
  const initials = useCallback(
    () => [
      {
        id: "Initial Visit - G0508 - 60 minutes - Telehealth consultation, critical care",
        title:
          "Initial Visit - G0508 - 60 minutes - Telehealth consultation, critical care",
      },
      {
        id: "G0425 - 30 minutes - Emergency Department or Initial Inpatient",
        title: "G0425 - 30 minutes - Emergency Department or Initial Inpatient",
      },
      {
        id: "G0426 - 50 minutes - Emergency Department or Initial Inpatient",
        title: "G0426 - 50 minutes - Emergency Department or Initial Inpatient",
      },
      {
        id: "G0427 - 70 minutes or more - Emergency Department or Initial Inpatient",
        title:
          "G0427 - 70 minutes or more - Emergency Department or Initial Inpatient",
      },
    ],
    []
  );

  const pft = useCallback(
    () => [
      {
        id: "94010 w/ Mod 26 - Spirometry (Pre-bronchodilator only)",
        title: "94010 w/ Mod 26 - Spirometry (Pre-bronchodilator only)",
      },
      {
        id: "94060 w/ Mod 26 - Bronchodilator Responsiveness tests (Pre & Post Bronchodilator)",
        title:
          "94060 w/ Mod 26 - Bronchodilator Responsiveness tests (Pre & Post Bronchodilator)",
      },
      {
        id: "94070 w/ Mod 26 - Bronchospasm Provocation Evaluation (Methacholine/Agent Challenge)",
        title:
          "94070 w/ Mod 26 - Bronchospasm Provocation Evaluation (Methacholine/Agent Challenge)",
      },
      {
        id: "94617 w/ Mod 26 - Exercise Induced Bronchospasm",
        title: "94617 w/ Mod 26 - Exercise Induced Bronchospasm",
      },
      {
        id: "94618 w/ Mod 26 - Pulmonary stress testing",
        title: "94618 w/ Mod 26 - Pulmonary stress testing",
      },
      {
        id: "94726 w/ Mod 26 - Plethysmograph Lung Volumes (VTG)",
        title: "94726 w/ Mod 26 - Plethysmograph Lung Volumes (VTG)",
      },
      {
        id: "94729 w/ Mod 26 - Diffusing Capacity (DLCO)",
        title: "94729 w/ Mod 26 - Diffusing Capacity (DLCO)",
      },
    ],
    []
  );

  const sleep_study = useCallback(
    () => [
      {
        id: "95782 w/ Mod 26 - Polysomnography; younger than 6 years, sleep staging with 4 or more additional parameters of sleep, attended by a technologist",
        title:
          "95782 w/ Mod 26 - Polysomnography; younger than 6 years, sleep staging with 4 or more additional parameters of sleep, attended by a technologist",
      },
      {
        id: "95783 w/ Mod 26 - Polysomnography; younger than 6 years, sleep staging with 4 or more additional parameters of sleep, with initiation of continuous positive airway pressure therapy or bi-level ventilation, attended by a technologist",
        title:
          "95783 w/ Mod 26 - Polysomnography; younger than 6 years, sleep staging with 4 or more additional parameters of sleep, with initiation of continuous positive airway pressure therapy or bi-level ventilation, attended by a technologist",
      },
      {
        id: "95808 w/ Mod 26 - Polysomnography; any age, sleep staging with 1-3 additional parameters of sleep, attended by a technologist",
        title:
          "95808 w/ Mod 26 - Polysomnography; any age, sleep staging with 1-3 additional parameters of sleep, attended by a technologist",
      },
      {
        id: "95810 w/ Mod 26 - Polysomnography; age 6 years or older, sleep staging with 4 or more additional parameters of sleep, attended by a technologist",
        title:
          "95810 w/ Mod 26 - Polysomnography; age 6 years or older, sleep staging with 4 or more additional parameters of sleep, attended by a technologist",
      },
      {
        id: "95811 w/ Mod 26 - Polysomnography; age 6 years or older, sleep staging with 4 or more additional parameters of sleep, with initiation of continuous positive airway pressure therapy or bilevel ventilation, attended by a technologist",
        title:
          "95811 w/ Mod 26 - Polysomnography; age 6 years or older, sleep staging with 4 or more additional parameters of sleep, with initiation of continuous positive airway pressure therapy or bilevel ventilation, attended by a technologist",
      },
    ],
    []
  );
  const interprofessional_call = useCallback(
    () => [
      {
        id: "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
        title:
          "99446 - Interprofessional telephone/Internet/electronic health assessment, 5-10 mins",
      },
      {
        id: "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
        title:
          "99447 - Interprofessional telephone/Internet/electronic health assessment, 11-20 mins",
      },
      {
        id: "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
        title:
          "99448 - Interprofessional telephone/Internet/electronic health assessment, 21-30 mins",
      },
      {
        id: "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
        title:
          "99449 - Interprofessional telephone/Internet/electronic health assessment, 31+ mins",
      },
    ],
    []
  );

  const telephonic_consultation = useCallback(
    () => [
      {
        id: "99441: Telephone E/M service; 5-10 mins",
        title: "99441: Telephone E/M service; 5-10 mins",
      },
      {
        id: "99442: Telephone E/M service; 11-20 mins",
        title: "99442: Telephone E/M service; 11-20 mins",
      },
      {
        id: "99443: Telephone E/M service, 21-30 mins",
        title: "99443: Telephone E/M service, 21-30 mins",
      },
    ],
    []
  );

  const { watch, register } = useFormContext();
  const follows = watch("critical_care_visit");
  const follow = useCallback(
    () => [
      {
        id: "Initial",
        title: "Initial",
        component: (
          <RadioField
            value="Service Type"
            list={initials()}
            listClass="grid md:grid-cols-2 gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Follow Up",
        title: "Follow Up",
        component: (
          <RadioField
            value="Service Type"
            list={followUp()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "PFT",
        title: "PFT",
        component: (
          <RadioField
            value="Service Type"
            list={pft()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Sleep Study",
        title: "Sleep Study",
        component: (
          <RadioField
            value="Service Type"
            list={sleep_study()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Interprofessional Call",
        title: "Interprofessional Call",
        component: (
          <RadioField
            value="Service Type"
            list={interprofessional_call()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
      {
        id: "Telephonic Consultation",
        title: "Telephonic Consultation",
        component: (
          <RadioField
            value="Service Type"
            list={telephonic_consultation()}
            listClass="grid gap-y-6 mt-3"
            rules={{
              required: "Service Type!",
            }}
            name="service_type"
          />
        ),
      },
    ],
    
  );
  useEffect(() => {
    if (follows) {
      const payload = follow().find((v) => v?.id === follows);
      setFollowUp(payload);
    }
  }, [follows]);

  return (
    <div className="">
      {/* <SelectField
        mainClass="mb-6"
        options={behavioralHealth()}
        value="Critical Care Facilities"
        placeholder="Select Critical Care Facilities"
        name="critical_care"
        rules={{
          required: "Critical Care Facilities Is Compulsory!",
          validate: (value) =>
            value !== "0" ? true : "Critical Care Facilities Is Compulsory!",
        }}
      /> */}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        className="mb-4"
        value={value}
        onChange={(event, value) => onDropDownChange(event, value)}
        options={behHealth}
        sx={{ width: "100%", mr: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register("critical_care", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            label="Select Critical Care Facilities"
          />
        )}
      />

      <RadioField
        value="Critical Care Visit"
        list={follow()}
        listClass="grid md:grid-cols-3 lg:grid-cols-4 gap-y-6 grid-cols-2 mt-3"
        rules={{
          required: "Follow Up!",
        }}
        name="critical_care_visit"
      />
      <div className="w-full mt-6">{FollowUp ? FollowUp?.component : null}</div>
    </div>
  );
}

export default BehavioralHealth;
