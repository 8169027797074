import React, { useState } from "react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Tabs({ routes, tabs, buttons }) {
  const [current, setCurrent] = useState(0);

  return (
    <div className="flex flex-wrap mb-4 items-center justify-between space-y-3 md:space-y-0">
      <div className="pb-2.5 space-x-2">
        {tabs?.map((tab, i) => (
          <Link
            onClick={() => setCurrent(i)}
            key={tab.name}
            to={`${routes}${tab.to}`}
            className={classNames(
              i === current
                ? "border-primary text-black-100 "
                : "border-transparent text-black-100 opacity-70 hover:text-black-100 hover:border-primary",
              "whitespace-nowrap py-2.5 pb-4 px-2 border-b-2 font-medium md:text-base text-sm"
            )}
            aria-current={tab.current ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </div>
      {buttons}
    </div>
  );
}
export default Tabs;
