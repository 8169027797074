// CONSTANTS
export const LOGIN = "LOGIN [VEEONECAL]";
export const TOKEN_VERIFY = "TOKEN VERIFY [VEEONECAL]";
// Actions
export const loginAction = (body) => {
  return (dispatch) => {
    return;
  };
};
export const verifyToken = () => {
  return (dispatch) => {
    const token = localStorage.getItem("Access_Token");
    const email = localStorage.getItem("user_email");
    if (token) {
      return dispatch({
        type: TOKEN_VERIFY,
        payload: {
          auth: true,
          tokens: token,
          user: email ? { email } : null,
        },
      });
    }
  };
};
