import { toast } from "react-toastify";
import {
  getDoctors,
  getEncounterSheet,
  getEncounterSheetsLast24Hours,
  getPendingRequest,
  LoginAdmin,
} from "utils/api/admin";

//Constants
export const LOGIN_ADMIN = "LOGIN ADMIN [VEEONECAL][ADMIN]";
export const GET_DOCTORS = "GET DOCTORS [VEEONECAL][ADMIN]";
export const GET_PENDING_REQUEST = "GET PENDING REQUEST [VEEONCAL][ADMIN]";
export const GET_ENCOUNTER_SHEETS = "GET ENCOUNTER SHEETS [VEEONCAL][ADMIN]";
export const GET_LAST_24_HOURS_ENCOUNTER_SHEETS =
  "GET LAST 24 HOURS ENCOUNTER SHEETS [VEEONCAL][ADMIN]";
export const GET_ALL_ENCOUNTER_SHEET = "GET ENCOUNTER SHEETS [VEEONCAL][ADMIN]";
//Actions
//Login Admin
export const onLoginAdmin = (payload) => {
  return (dispatch) => {
    return LoginAdmin(payload).then((response) => {
      if (response.data?.success) {
        const { Data } = response?.data;
        localStorage.setItem("Access_Token", Data?.access_token);
        toast.success("Login Successfully!");
        return dispatch({
          type: LOGIN_ADMIN,
        });
      } else {
        toast.error(response?.data?.message);
      }
    });
  };
};
//Get Doctors
export const onGetDoctors = () => {
  return (dispatch) => {
    return getDoctors().then((response) => {
      return dispatch({
        type: GET_DOCTORS,
        payload: response?.data?.Data,
      });
    });
  };
};
//Get Pending Request
export const onGetPendingRequest = () => {
  return (dispatch) => {
    return getPendingRequest().then((response) => {
      const { Data } = response?.data;
      return dispatch({
        type: GET_PENDING_REQUEST,
        payload: Data,
      });
    });
  };
};
//Get Encounter Sheet Request
export const onGetEncounterSheets = () => {
  return (dispatch) => {
    return getEncounterSheet().then((response) => {
      const { Data } = response?.data;
      return dispatch({
        type: GET_ENCOUNTER_SHEETS,
        payload: Data,
      });
    });
  };
};
//Get Encounter Sheet Last 24 Hours Request
export const onGetLast24HoursEncounterSheets = () => {
  return (dispatch) => {
    return getEncounterSheetsLast24Hours().then((response) => {
      const { Data } = response?.data;
      return dispatch({
        type: GET_LAST_24_HOURS_ENCOUNTER_SHEETS,
        payload: Data,
      });
    });
  };
};
//Get All Encounter Sheets
export const onGetAllEncounterSheet = () => {
  return (dispatch) => {
    return getEncounterSheet().then((response) => {
      return dispatch({
        type: GET_ALL_ENCOUNTER_SHEET,
        payload: response?.data?.Data,
      });
    });
  };
};
