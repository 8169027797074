import Forgot from "../../components/Forgot";
export default function AdminReset({ reset,adminText }) {
  return (
    <>
      <Forgot adminText={adminText}
        action="/create-new-password/admin"
        reset={reset}
      />
    </>
  );
}
